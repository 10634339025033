import { MailFilled } from "@ant-design/icons";
import { Form, Input, Space, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/components/button";
import Header from "src/components/header";
import RequestAppAction from "src/store/slices/appActions";
import styles from "./forgotPassword.module.scss";

const { Text, Title } = Typography;

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [validate, setValidate] = useState(false);
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();

  const onFinish = (val: { email: string }) => {
    const param = {
      email: val?.email,
    };

    dispatch(
      RequestAppAction.postforgetPassword({
        data: param,
        cbSuccess: (res: { data: { message: string } }) => {
          notification.success({ message: res.data.message });
          setValidate(true);
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  return (
    <div className={styles.login_form_container}>
      <Space
        onClick={() => navigate("/")}
        align="center"
        className={styles.login_header}
      >
        <Header />
      </Space>
      {validate ? (
        <Space className={styles.validate_div} direction="vertical">
          <Title level={3}>{t("heading.checkMail")}</Title>

          <Title level={3} className={styles.parah}>
            {t("heading.desc")}
          </Title>
          <Title
            level={3}
            onClick={() => {
              navigate("/login");
            }}
            className={styles.underline_text}
          >
            {t("underlineText.signIn")}
          </Title>
        </Space>
      ) : (
        <Space direction="vertical" className={styles.login_form_wrapper}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className={`login-form ${styles.login_form}`}
          >
            <Form.Item
              name="email"
              label={t("fields.email")}
              rules={[
                { required: true, message: "Please input your Username!" },
                { type: "email", message: t("fields.emailCorrect") },
              ]}
            >
              <Input
                prefix={<MailFilled className={styles.site_form_item_icon} />}
                placeholder="Email"
                className={styles.login_inputs}
              />
            </Form.Item>
            <Form.Item>
              <Button
                title={t("button.sendMail")}
                buttonType="submit"
                btnClass="full_btn"
              />
            </Form.Item>
          </Form>

          <div className={styles.forgot_password}>
            <Text
              onClick={() => {
                navigate("/login");
              }}
              className={styles.underline_text}
            >
              {t("underlineText.signIn")}
            </Text>
          </div>
        </Space>
      )}
    </div>
  );
};

export default ForgotPassword;
