import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  reportState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  reportData: null,
  permissionLevels: {
    isLoading: false,
    roles: null,
  },
};

export const reportSlice = createSlice({
  name: "report",
  initialState: INITIAL_STATE,
  reducers: {
    fetchReports: (state, { payload }) => {
      state.reportState.isLoading = true;
      payload;
    },
    fetchReportsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      reportState: {
        ...state.reportState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      reportData: action.payload.data,
    }),
    fetchReportsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      reportState: {
        ...state.reportState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearReports: (state) => ({
      ...state,
      reportState: {
        ...state.reportState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      reportData: null,
    }),
  },
});

export const {
  fetchReports,
  fetchReportsSuccess,
  fetchReportsFailure,
  clearReports,
} = reportSlice.actions;
export const reportsReducer = reportSlice.reducer;
