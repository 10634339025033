import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  addNewUserFailure,
  addNewUserSuccess,
} from "../slices/features/addNewUser";
import { RequestTypes } from "../types";

const usersService = new AppService();

function* addnewUser(action: any) {
  const { payload } = action;
  const { id, data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      usersService.newUser,
      baseUrl,
      id,
      data
    );

    payload?.cbSuccess && payload?.cbSuccess();

    yield put(addNewUserSuccess({ ...response }));
  } catch (error: any) {
    toast.error(error?.data.errors.message || "Error while adding user");

    const { statusCode, statusText } = error;
    yield put(addNewUserFailure({ statusCode, statusText }));
  }
}

export function* watchNewUser() {
  yield takeLatest(RequestTypes.ADD_USER_REQUEST, addnewUser);
}
