import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  planState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const planSlice = createSlice({
  name: "plan",
  initialState: INITIAL_STATE,
  reducers: {
    fetchPlans: (state) => {
      state.planState.isLoading = true;
    },
    fetchPlansSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      planState: {
        ...state.planState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchPlansFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      planState: {
        ...state.planState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const { fetchPlans, fetchPlansFailure, fetchPlansSuccess } =
  planSlice.actions;
export const planReducer = planSlice.reducer;
