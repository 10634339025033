import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { updateUserFailure, updateUserSuccess } from "../slices/features/users";
import { RequestTypes } from "../types";

const usersService = new AppService();

function* updateUser(action: any) {
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      usersService.updateUser,
      baseUrl,
      action.payload
    );

    toast.success("user updated successfully");

    yield put(updateUserSuccess({ ...response }));
    action.payload?.cbSuccess && action.payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(updateUserFailure({ statusCode, statusText }));
  }
}

export function* watchUpdateUser() {
  yield takeLatest(RequestTypes.UPDATE_USER, updateUser);
}
