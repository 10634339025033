import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchCompaniesDataFailure,
  fetchCompaniesDataSuccess,
} from "../slices/features/company";
import { RequestTypes } from "../types";

const companyService = new AppService();

function* fetchCompany(action: any) {
  const { page, limit, search, status } = action.payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      companyService.fetchCompaniesData,
      baseUrl,
      page,
      limit,
      status,
      search
    );

    yield put(fetchCompaniesDataSuccess({ ...response }));

    action?.payload?.cbSuccess && action?.payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchCompaniesDataFailure({ statusCode, statusText }));
  }
}

export function* watchComapny() {
  yield takeLatest(RequestTypes.FETCH_COMPANIES_DATA, fetchCompany);
}
