import React from "react";

import UsersList from "src/components/users-list";

export const TextifyUsers: React.FC = () => {
  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <UsersList />
    </div>
  );
};
