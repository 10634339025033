import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  usersStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  usersData: null,
  updatedData: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUsersData: (state, { payload }) => {
      state.usersStates.isLoading = true;
      payload;
    },
    fetchUsersDataSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      usersStates: {
        ...state.usersStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      usersData: action.payload.data,
    }),
    fetchUsersDataFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      usersStates: {
        ...state.usersStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),

    initiateUpdateUser: (state, { payload }) => {
      state.usersStates.isLoading = true;
      payload;
    },
    updateUserSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      usersStates: {
        ...state.usersStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      updatedData: action.payload.data,
    }),
    updateUserFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      usersStates: {
        ...state.usersStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),

    initiateDeleteUser: (state, { payload }) => {
      state.usersStates.isLoading = true;
      payload;
    },
    deleteUserSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      usersStates: {
        ...state.usersStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    deleteUserFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      usersStates: {
        ...state.usersStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearUserData: (state) => ({
      ...state,
      usersStates: {
        isLoading: false,
        error: null,
      },
      apiStatus: {
        statusCode: null,
        statusText: null,
      },
      usersData: null,
      updatedData: null,
    }),
  },
});

export const {
  fetchUsersData,
  fetchUsersDataSuccess,
  fetchUsersDataFailure,
  initiateUpdateUser,
  updateUserSuccess,
  updateUserFailure,
  initiateDeleteUser,
  deleteUserSuccess,
  deleteUserFailure,
  clearUserData,
} = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
