import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { LocalStorageService } from "src/services/local-storage";
import { getBaseUrl } from "../selectors/features/userSettings";
import { logoutUser } from "../slices/features/auth";
import { clearCompanies } from "../slices/features/company";
import { clearHistory } from "../slices/features/history";
import { clearReportsById } from "../slices/features/innerReports";
import { clearReports } from "../slices/features/reports";
import { clearRoles } from "../slices/features/roles";
import {
  postSignoutFailure,
  postSignoutSuccess,
} from "../slices/features/signout";
import { clearUserDetails } from "../slices/features/userDetails";
import { clearUserData } from "../slices/features/users";
import { RequestTypes } from "../types";

const appService = new AppService();

const localStorageService = new LocalStorageService();

function* postSignout(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.postSignout,
      baseUrl
    );

    if (response) {
      payload?.cbSuccess && payload?.cbSuccess();

      yield put(logoutUser());
      yield put(clearCompanies());
      yield put(clearHistory());
      yield put(clearReportsById());
      yield put(clearReports());
      yield put(clearRoles());
      yield put(clearUserData());
      yield put(clearUserDetails());
      yield call(localStorageService.remove, "user");
      yield call(localStorageService.remove, "userData");
      yield call(localStorageService.remove, "authToken");
    }

    yield put(postSignoutSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(logoutUser());
    yield call(localStorageService.remove, "user");
    yield call(localStorageService.remove, "userData");
    yield call(localStorageService.remove, "authToken");

    yield put(postSignoutFailure({ statusCode, statusText }));
  }
}

export function* watchPostSignout() {
  yield takeLatest(RequestTypes.SIGN_OUT_REQUEST, postSignout);
}
