import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  passwordStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  passwordData: null,
};

export const passwordSlice = createSlice({
  name: "password",
  initialState: INITIAL_STATE,
  reducers: {
    putPassword: (state) => {
      state.passwordStates.isLoading = true;
    },
    putPasswordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      passwordStates: {
        ...state.passwordStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      passwordData: action.payload.data,
    }),
    putPasswordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      passwordStates: {
        ...state.passwordStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
    putPassword,
    putPasswordSuccess,
    putPasswordFailure,
} = passwordSlice.actions;
export const passwordReducer = passwordSlice.reducer;