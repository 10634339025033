/* eslint-disable */
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button as Btn,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from "antd";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CompanyStatus,
  categoryOptions,
  labelStyle,
  timezones,
} from "src/constants/samples";
import Colors from "src/themes/colors";
import Button from "../button";
import Switch from "../switch";

import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTimezoneSelect } from "react-timezone-select";
import { Limit } from "src/constants/endpoints";
import { UserDataType, Users } from "src/constants/types";
import { getHistoryData } from "src/store/selectors/features/history";
import { getRolesData } from "src/store/selectors/features/roles";
import { getUsersData } from "src/store/selectors/features/users";
import RequestAppAction from "src/store/slices/appActions";
import {
  deleteCompanyRecord,
  fetchCompaniesData,
} from "src/store/slices/features/company";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import { initiateUpdateCompany } from "src/store/slices/features/updateCompany";
import {
  clearUserData,
  fetchUsersData,
  initiateDeleteUser,
  initiateUpdateUser,
} from "src/store/slices/features/users";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import UserTable from "../user-table";
import styles from "./userList.module.scss";
import { ROLES } from "src/constants/roles";

const { Title, Text } = Typography;

export const userColumns: ColumnsType<UserDataType> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: 100,
  },
  {
    title: "DIDs",
    dataIndex: "did",
    key: "did",
    fixed: "left",
  },
  {
    title: "Monthly Usage",
    dataIndex: "monthlyusage",
    key: "monthlyusage",
    fixed: "left",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const titleStyles = {
  borderBottom: `0.1rem solid ${Colors.Secondary}`,
  cursor: "pointer",
  color: Colors.Secondary,
};

const unSelectedTitleStyles = {
  borderBottom: `0.1rem solid transparent`,
  cursor: "pointer",
};

const { Option } = Select;

const UsersList = () => {
  const [expand, setExpand] = useState<number | null>(null);
  const [userExpand, setUserExpand] = useState<number | null>(null);
  const [companyExpand, setCompanyExpand] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<string>("users");
  const [isChecked, setIsChecked] = useState(false);
  const [currentHistories, setCurrentHistories] = useState([]);
  const dispatch = useDispatch();
  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );
  const userData: any = useSelector(getUsersData);
  const [isLoading, setIsLoading] = useState(false);
  const roles: any = useSelector(getRolesData);
  const histories: any = useSelector(getHistoryData);
  const navigate = useNavigate();
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const inputRef = useRef<any>(null);
  const [billed, setBilled] = useState(false);
  const { t } = useTranslation();

  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  const pathName: any = location.pathname;
  const tempId = pathName.match(/\d+$/);
  // eslint-disable-next-line
  const id = tempId[0];

  useEffect(() => {
    if (histories) {
      const tempData = histories.map((item: any) => {
        const newItem = { ...item };

        newItem.description = newItem.activity;
        delete newItem.activity;
        return newItem;
      });

      setCurrentHistories(tempData);
    }
  }, [histories]);

  useEffect(() => {
    setIsLoading(true);

    if (isChecked) {
      const data: { id: string; activeOnly?: boolean; search?: string } = {
        id: id,
      };

      if (searchVal) {
        data["search"] = searchVal;
      }

      dispatch(
        fetchUsersData({
          ...data,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    } else {
      dispatch(
        fetchUsersData({
          id: id,
          search: searchVal,
          activeOnly: true,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    }
  }, [isChecked]);

  useEffect(() => {
    dispatch(initiateFetchRoles());

    if (id) {
      dispatch(RequestAppAction.fetchHistory(id));
    }

    return () => {
      dispatch(clearUserData());
    };
  }, []);

  const handleSubmit = (values: any) => {
    setIsLoading(true);

    const updateComPayload = {
      ...values,
      id: userData.id,
    };

    dispatch(
      initiateUpdateCompany({
        data: updateComPayload,
        cbSuccess: () => {
          const data = {
            page: 1,
            limit: Limit,
          };

          dispatch(fetchCompaniesData(data));
        },
      })
    );
    setCompanyExpand(!companyExpand);
    setTimeout(() => {
      const myData: {
        id: string | null;
        activeOnly?: boolean;
        search?: string;
      } = {
        id: id,
      };

      if (!isChecked) {
        myData["activeOnly"] = true;
      }

      if (searchVal) {
        myData["search"] = searchVal;
      }

      dispatch(
        fetchUsersData({
          ...myData,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    }, 1000);
  };

  useEffect(() => {
    if (userData) {
      setUserExpand(null);
    }
  }, [userData]);

  const handleUpdateUser = (values: any, userId: number) => {
    let permissionNumber = values?.permissionLevel;

    setIsLoading(true);

    if (typeof permissionNumber === "string") {
      permissionNumber = roles[0]?.id;
    }

    const updateUserPayload: any = {
      id: userId,
      roleId: permissionNumber,
    };

    if (values?.username !== selectedUser?.username) {
      updateUserPayload["username"] = values?.username;
    }

    if (values?.contactName !== selectedUser?.name) {
      updateUserPayload["name"] = values?.contactName;
    }

    if (values?.mainEmail !== selectedUser?.email) {
      updateUserPayload["email"] = values?.mainEmail;
    }

    if (values?.additionalEmail !== selectedUser?.additionalEmail) {
      updateUserPayload["additionalEmail"] = values?.additionalEmail;
    }

    if (values?.status !== selectedUser?.status) {
      updateUserPayload["status"] = values?.status;
    }

    dispatch(initiateUpdateUser(updateUserPayload));

    setTimeout(() => {
      const myData: {
        id: string | null;
        activeOnly?: boolean;
        search?: string;
      } = {
        id: id,
      };

      if (!isChecked) {
        myData["activeOnly"] = true;
      }

      if (searchVal) {
        myData["search"] = searchVal;
      }

      dispatch(
        fetchUsersData({
          ...myData,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    }, 1000);
  };

  const handleDeleteUser = (userId: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      content: "This action cannot be undone.",
      onOk() {
        dispatch(initiateDeleteUser(userId));

        setTimeout(() => {
          const myData: {
            id: string | null;
            activeOnly?: boolean;
            search?: string;
          } = {
            id: id,
          };

          if (!isChecked) {
            myData["activeOnly"] = true;
          }

          if (searchVal) {
            myData["search"] = searchVal;
          }

          dispatch(
            fetchUsersData({
              ...myData,
              cbSuccess: () => {
                setIsLoading(false);
              },
              cbFailure: () => {
                setIsLoading(false);
              },
            })
          );
        }, 1000);
      },
    });
  };

  const navigateToNewUser = () => {
    return (
      <Link to={`/accounts/${userData?.id}/add-new-user`} state={userData}>
        <Button title="Add User" icon={<PlusCircleOutlined />} />
      </Link>
    );
  };

  const handleDeleteCompany = () => {
    Modal.confirm({
      title: t("modal.confirmDelete"),
      content: t("modal.deleteContent"),
      onOk() {
        dispatch(
          deleteCompanyRecord({
            id: userData?.id,
            cbSuccess: () => {
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            },
          })
        );
      },
    });
  };

  interface thisUser {
    roleId: number | string;
  }

  const rolesName = (thisUser: thisUser) => {
    const currentRole = roles?.filter(
      (role: any) => role?.id == thisUser?.roleId
    );

    if (currentRole) {
      return currentRole[0]?.id;
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    const getData = setTimeout(() => {
      if (!initialLoad) {
        setIsLoading(true);
      }

      const myData: {
        id: string | null;
        search: string;
        activeOnly?: boolean;
      } = {
        id: id,
        search: searchVal,
      };

      if (!isChecked) {
        myData["activeOnly"] = true;
      }

      dispatch(
        fetchUsersData({
          ...myData,
          cbSuccess: () => {
            setIsLoading(false);
            setInitialLoad(false);
          },
          cbFailure: () => {
            setIsLoading(false);
            setInitialLoad(false);
          },
        })
      );
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchVal]);

  const companyDetails = (name: string, detail: string) => {
    return (
      <Space style={{ paddingRight: "2rem", paddingTop: "1rem" }}>
        <Col
          style={{
            maxWidth: "8rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Text strong style={{ color: "rgba(0,0,0,0.5)" }}>
              {name}
            </Text>
          </div>
          <Text
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {detail || "-"}
          </Text>
        </Col>
      </Space>
    );
  };

  useEffect(() => {
    if (!userExpand) {
      setSelectedUser(null);
    }
  }, [userExpand]);

  useEffect(() => {
    setBilled(userData?.billedExternally);
  }, [companyExpand]);

  const onResetPassword = (email: string) => {
    if (!email) return;

    const param = { email: email };

    dispatch(
      RequestAppAction.postforgetPassword({
        data: param,
        cbSuccess: (res: { data: { message: string } }) => {
          notification.success({ message: res.data.message });
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Spin
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
        spinning={isLoading}
      ></Spin>
      <div style={{ height: "100%", overflow: "hidden", width: "100%" }}>
        <Space
          direction="vertical"
          size={"large"}
          style={{ position: "relative" }}
          className={styles.user_list_wrapper_header}
        >
          <Space
            className={styles.user_list_header}
            style={{ marginTop: !companyExpand ? "-0.8rem" : 0 }}
            size={"large"}
          >
            {companyExpand ? (
              <Form
                initialValues={{
                  ["name"]: userData?.name,
                  ["timeZone"]: userData?.timeZone,
                  ["status"]: userData?.status,
                  ["category"]: userData?.category,
                  ["notes"]: userData?.notes,
                  ["billedExternally"]: userData?.billedExternally,
                }}
                layout={"vertical"}
                onFinish={handleSubmit}
              >
                <Row gutter={16}>
                  <Col>
                    <Form.Item
                      style={{ width: "18rem" }}
                      name="name"
                      label="company name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter company name" />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      style={{ width: "18rem" }}
                      name="timeZone"
                      label="timezone"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Timezone",
                        },
                      ]}
                    >
                      <Select placeholder="Select Timezone">
                        {options?.map((timezone, index) => (
                          <Option key={index} value={timezone.label}>
                            {timezone.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      style={{ width: "18rem" }}
                      name="status"
                      label="status"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Status",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "18rem" }}
                        placeholder="Select Status"
                      >
                        {Object.values(CompanyStatus).map((timezone, index) => (
                          <Option key={index} value={timezone}>
                            {capitalizeFirstLetter(
                              timezone?.toString()?.toLocaleLowerCase()
                            )}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      style={{ width: "18rem" }}
                      name="category"
                      label="category"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Category",
                        },
                      ]}
                    >
                      <Select placeholder="Select Category">
                        {categoryOptions.map((category, index) => (
                          <Option key={index} value={category}>
                            {capitalizeFirstLetter(
                              category?.toString()?.toLocaleLowerCase()
                            )}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      style={{ width: "18rem" }}
                      name="notes"
                      label="notes"
                    >
                      <Input placeholder="Notes" />
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    span={8}
                  >
                    <Form.Item
                      style={{
                        margin: 0,
                      }}
                      valuePropName="checked"
                      name="billedExternally"
                    >
                      <Checkbox
                        onChange={(e) => {
                          setBilled(e.target.checked);
                        }}
                        checked={billed}
                      >
                        <Text>Billed externally</Text>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item style={{ marginTop: "0.5rem" }}>
                  <Space align="end" className={styles.form_button_wrapper}>
                    <Space size="middle" align="center">
                      <Button
                        title="Cancel"
                        btnClass="white_btn"
                        onBtnClick={() => setCompanyExpand(!companyExpand)}
                      ></Button>
                      <Button title="Save" buttonType="submit"></Button>
                    </Space>
                  </Space>
                </Form.Item>
              </Form>
            ) : (
              <Row gutter={[10, 2]} style={{ marginBottom: "0.625rem" }}>
                {companyDetails("ID", userData?.id)}

                {companyDetails("Company Name", userData?.name)}

                {companyDetails(
                  "Timezone",
                  userData?.timeZone?.replace(/\([^\)]+\)\s*/, "")?.trim()
                )}

                {companyDetails(
                  "Status",
                  capitalizeFirstLetter(
                    userData?.status?.toString()?.toLocaleLowerCase()
                  )
                )}
                {companyDetails(
                  "Category",
                  capitalizeFirstLetter(
                    userData?.category?.toString()?.toLocaleLowerCase()
                  )
                )}

                <Space style={{ paddingRight: "2rem", paddingTop: "1rem" }}>
                  <Col
                    style={{
                      maxWidth: "8rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Text strong style={{ color: "rgba(0,0,0,0.5)" }}>
                        Note
                      </Text>
                    </div>
                    <Tooltip
                      style={{ borderRadius: 4 }}
                      title={userData?.notes}
                    >
                      <Text
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "default",
                        }}
                      >
                        {userData?.notes || "-"}
                      </Text>
                    </Tooltip>
                  </Col>
                </Space>

                {companyDetails(
                  " Current Month Usage",
                  `${userData?.usageCount || 0}/${userData?.usageLimit || 0}`
                )}
                {companyDetails(
                  "Plan",
                  `${userData?.stripeProducts?.stripeProductName ?? "-"}`
                )}
                {companyDetails(
                  "Billed externally",
                  `${userData?.billedExternally ?? "-"}`
                )}
              </Row>
            )}

            {!companyExpand ? (
              <Space style={{ marginTop: "1rem" }} align="start" size={12}>
                <Space
                  className={styles.search_div}
                  style={{
                    background: showSearch ? Colors.mediumGrey : Colors.White,
                    width: showSearch ? "15rem" : "auto",
                  }}
                >
                  <Btn
                    shape="circle"
                    style={{
                      background: "transparent",
                      border: showSearch
                        ? "none"
                        : `0.1rem solid ${Colors.borderGray}`,
                    }}
                    icon={<SearchOutlined />}
                    onClick={() => setShowSearch(!showSearch)}
                  />
                  {showSearch && (
                    <div style={{ display: "flex", paddingRight: "0.5rem" }}>
                      <Input
                        ref={inputRef}
                        onChange={(e) => {
                          setSearchVal(e.target.value);
                        }}
                        placeholder="Search by name or email"
                        size={showSearch ? "middle" : "large"}
                        className={styles.search_input}
                      />
                      <CloseOutlined
                        onClick={() => {
                          setShowSearch(false);
                          setSearchVal("");
                        }}
                      />
                    </div>
                  )}
                </Space>
                <Button
                  shape="circle"
                  onBtnClick={() => setCompanyExpand(!companyExpand)}
                  title=""
                  icon={<EditOutlined />}
                ></Button>
                <Button
                  shape="circle"
                  title="title"
                  onBtnClick={() => {
                    handleDeleteCompany();
                  }}
                  icon={<DeleteOutlined />}
                ></Button>
              </Space>
            ) : (
              <></>
            )}
          </Space>
        </Space>
        <Space
          direction="vertical"
          size={"large"}
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            overflow: "hidden",
          }}
          className={styles.user_list_wrapper}
        >
          <Space
            direction="vertical"
            style={{
              paddingBottom: 0,
              marginTop: "-1rem",
              paddingRight: 20,
              display: "flex",
              height: "100%",
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
            className={styles.user_list_content_headerings_wrapper}
          >
            <Space
              size={"large"}
              style={{ marginBottom: "-0.7rem" }}
              className={styles.user_list_content_headerings}
            >
              <Space style={{ margin: 0 }}>
                <Title
                  level={5}
                  style={
                    selectedTab === "users"
                      ? titleStyles
                      : unSelectedTitleStyles
                  }
                  onClick={() => setSelectedTab("users")}
                >
                  User & DIDs
                </Title>
                <Title
                  onClick={() => {
                    if (id) {
                      dispatch(RequestAppAction.fetchHistory(id));
                    }
                    setSelectedTab("activity");
                  }}
                  level={5}
                  style={
                    selectedTab === "activity"
                      ? titleStyles
                      : unSelectedTitleStyles
                  }
                >
                  Activity History
                </Title>
              </Space>
              {selectedTab === "users" ? (
                <Space
                  size="middle"
                  align="center"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 0,
                  }}
                >
                  <Switch
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e)}
                    label="Show Inactive"
                  />
                  {navigateToNewUser()}
                </Space>
              ) : (
                <></>
              )}
            </Space>
            <Divider style={{ margin: 0 }} />
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "60vh",
                position: "relative",
                overflowY: "auto",
                flexDirection: "column",
              }}
            >
              {selectedTab === "users" ? (
                userData?.users?.length > 0 ? (
                  <>
                    {!companyExpand &&
                      userData?.users?.map((user: Users, index: number) => (
                        <div key={index} style={{ paddingBottom: "1rem" }}>
                          <Space
                            direction="vertical"
                            size={"large"}
                            style={{
                              borderRadius: "0.625rem",
                              width: "100%",
                              border: `0.06rem solid ${
                                user.status === CompanyStatus.Active
                                  ? Colors.Green
                                  : Colors.LightGray
                              }`,
                            }}
                          >
                            <Space
                              align="center"
                              size={"large"}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor:
                                  user.status === CompanyStatus.Active
                                    ? Colors.LightGreen
                                    : user.status === CompanyStatus.Inactive
                                    ? Colors.LightGray
                                    : Colors.lightYellow,
                                borderRadius: "0.625rem 0.625rem 0 0",
                                padding: "0.4rem 0.825rem 0.4rem 0.825rem",
                                width: "100%",
                              }}
                            >
                              <Space
                                style={{ margin: 0, minWidth: "300px" }}
                                size="middle"
                                align="center"
                              >
                                <Title level={5} style={{ margin: 0 }}>
                                  {`${index + 1}. ${
                                    user?.username || user?.email
                                  }`}
                                </Title>
                                {
                                  ROLES.SUPERADMINFULL?.toLowerCase() === loggedIn?.role?.toLowerCase()
                                  && user?.email
                                  && (
                                    <Button
                                      btnClass="white_btn"
                                      title="Reset Password"
                                      onBtnClick={() => onResetPassword(user.email)}
                                    />
                                  )
                                }
                              </Space>
                              <Space
                                style={{ margin: 0 }}
                                size="middle"
                                align="center"
                              >
                                <Button
                                  shape="circle"
                                  title=""
                                  icon={<EditOutlined />}
                                  onBtnClick={() => (
                                    setUserExpand(user.id),
                                    setSelectedUser(user)
                                  )}
                                ></Button>
                                <Button
                                  shape="circle"
                                  title=""
                                  icon={<DeleteOutlined />}
                                  onBtnClick={() => handleDeleteUser(user.id)}
                                ></Button>
                                <Button
                                  btnClass="white_btn"
                                  title="See More"
                                  icon={
                                    expand === index ? (
                                      <ArrowUpOutlined
                                        style={{ fontSize: 10 }}
                                      />
                                    ) : (
                                      <ArrowDownOutlined
                                        style={{ fontSize: 10 }}
                                      />
                                    )
                                  }
                                  onBtnClick={() => {
                                    setUserExpand(null);
                                    if (expand === index) setExpand(null);
                                    else setExpand(index);
                                  }}
                                />
                              </Space>
                            </Space>

                            {userExpand === user.id ? (
                              <Space
                                size={"large"}
                                direction="vertical"
                                className={styles.user_list_items_heading}
                              >
                                <div className={styles.form_wrapper}>
                                  <Form
                                    layout={"vertical"}
                                    onFinish={(values) =>
                                      handleUpdateUser(values, user?.id)
                                    }
                                    initialValues={{
                                      ["username"]: user?.username,
                                      ["contactName"]: user?.name,
                                      ["permissionLevel"]: roles?.filter(
                                        (role: any) => role?.id === user?.roleId
                                      ).name,
                                      ["mainEmail"]: user?.email,
                                      ["additionalEmail"]:
                                        user?.additionalEmail,
                                    }}
                                  >
                                    <Row gutter={16}>
                                      <Col span={8}>
                                        <Form.Item
                                          name="username"
                                          label="Username"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter username",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Enter Username" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          name="contactName"
                                          label="Contact Name"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter contact name",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Enter Contact Name"
                                            defaultValue={user?.name}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={10}>
                                        <Form.Item
                                          name="permissionLevel"
                                          label="Permission Level"
                                          initialValue={rolesName(user)}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please select permission level",
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Select Permission Level">
                                            {roles?.map((role: any) => (
                                              <Option
                                                key={role?.id}
                                                value={role?.id}
                                              >
                                                {role?.name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={6}>
                                        <Form.Item
                                          name="status"
                                          initialValue={user?.status}
                                          label="status"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please Select Status",
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Select Status">
                                            {Object.values(CompanyStatus).map(
                                              (timezone, ind) => (
                                                <Option
                                                  key={ind}
                                                  value={timezone}
                                                >
                                                  {capitalizeFirstLetter(
                                                    timezone
                                                      ?.toString()
                                                      ?.toLocaleLowerCase()
                                                  )}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          name="mainEmail"
                                          label="Main Email"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter main email",
                                            },
                                            {
                                              type: "email",
                                              message:
                                                "Please enter a valid email address",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Enter Main Email" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          rules={[
                                            {
                                              type: "email",
                                              message:
                                                "Please enter a valid email address",
                                            },
                                          ]}
                                          name="additionalEmail"
                                          label="Additional Email"
                                        >
                                          <Input placeholder="Enter Additional Email" />
                                        </Form.Item>
                                      </Col>
                                    </Row>

                                    <Form.Item>
                                      <Space
                                        align="end"
                                        className={styles.form_button_wrapper}
                                      >
                                        <Space size="middle" align="center">
                                          <Button
                                            onBtnClick={() =>
                                              setUserExpand(null)
                                            }
                                            title="Cancel"
                                            btnClass="white_btn"
                                          ></Button>
                                          <Button
                                            title="Save"
                                            buttonType="submit"
                                          ></Button>
                                        </Space>
                                      </Space>
                                    </Form.Item>
                                  </Form>
                                </div>
                              </Space>
                            ) : (
                              <Space
                                size={"large"}
                                direction="vertical"
                                className={styles.user_list_items_heading}
                              >
                                <Row
                                  gutter={[16, 0]}
                                  style={{ marginBottom: "0.625rem" }}
                                >
                                  <Col span={3}>
                                    <Text className={styles.font_style}>
                                      User Name
                                    </Text>
                                  </Col>
                                  <Col span={4}>
                                    <Text className={styles.font_style}>
                                      Contact Name
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text className={styles.font_style}>
                                      Main Email
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text className={styles.font_style}>
                                      Additional Email
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text className={styles.font_style}>
                                      Permission Level
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text className={styles.font_style}>
                                      Status
                                    </Text>
                                  </Col>
                                  <Col span={5}>
                                    <Text className={styles.font_style}>
                                      Current Month Usage
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text style={{ textTransform: "none" }}>
                                      {user?.username}
                                    </Text>
                                  </Col>
                                  <Col span={4}>
                                    <Text style={{ textTransform: "none" }}>
                                      {user?.name}
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text style={{ textTransform: "none" }}>
                                      {" "}
                                      {user?.email}{" "}
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text style={{ textTransform: "none" }}>
                                      {user.additionalEmail}
                                    </Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text>{user?.roles?.name}</Text>
                                  </Col>
                                  <Col span={3}>
                                    <Text>{user?.status}</Text>
                                  </Col>
                                  <Col span={5}>
                                    <Text>
                                      {user?.usageCount > 0 &&
                                      user?.usageLimit > 0
                                        ? `${user?.usageCount || 0}/${
                                            user?.usageLimit || 0
                                          } `
                                        : user.usageCount > 0
                                        ? user?.usageCount
                                        : 0}
                                    </Text>
                                  </Col>
                                </Row>
                                {expand === index ? (
                                  <UserTable
                                    isChecked={isChecked}
                                    columns={userColumns}
                                    userId={user.id}
                                    data={user.dids}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Space>
                            )}
                          </Space>
                        </div>
                      ))}{" "}
                  </>
                ) : (
                  <Space
                    size="middle"
                    align="center"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div style={{ paddingTop: "2rem" }}>
                      <FileOutlined
                        style={{ fontSize: "3rem", color: Colors.LightGray }}
                      />
                    </div>
                    <div style={{ color: Colors.LightGray }}>
                      No Record found
                    </div>
                  </Space>
                )
              ) : selectedTab === "activity" && !companyExpand ? (
                <div style={{ marginBottom: "120px" }}>
                  <Space
                    direction="vertical"
                    size={"large"}
                    style={{
                      borderRadius: "0.625rem",
                      width: "100%",
                    }}
                  >
                    <Steps
                      progressDot
                      current={currentHistories.length}
                      responsive
                      direction="vertical"
                      items={currentHistories}
                    />
                  </Space>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default UsersList;
