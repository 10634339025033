import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const newUsersSelector = (state: any) => state.features.did;

export const getBaseUrl = createSelector(
  newUsersSelector,
  (app) => app.baseUrl
);

export const getDidData = createSelector(
  newUsersSelector,
  (app) => app.didData
);

export const getDidStates = createSelector(
  newUsersSelector,
  (app) => app.didStates
);

export const getDidsLoadingState = createSelector(
  getDidStates,
  (didStates) => didStates.isLoading
);
