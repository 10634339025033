// set roles according to your needs
export enum ROLES {
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  SUPERADMINFULL = "Super Admin (Full)",
  SUPERADMINLIMITED = "Super Admin (Limited)",
  SUPERADMINUNITE = "Super Admin (Unite)",
  MANAGER = "MANAGER",
  CUSTOMER = "CUSTOMER",
  GUEST = "GUEST",
  USER = "USER",
}

export enum FONTFAMILY {
  ROBOTO = "Roboto",
}
