import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/userSettings";
import {
  putUserSettingsFailure,
  putUserSettingsSuccess,
} from "../slices/features/userSettings";
import { RequestTypes } from "../types";

const appService = new AppService();

function* updateUserSettings(action: any) {
  const { payload } = action;
  const { emailNotification, audioNotification } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const data = {
      emailNotification: emailNotification,
      audioNotification: audioNotification,
    };

    const response: AxiosResponse<any> = yield call(
      appService.putUserSettings,
      baseUrl,
      data
    );

    yield put(putUserSettingsSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(putUserSettingsFailure({ statusCode, statusText }));
  }
}

export function* watchUpdateUserSettings() {
  yield takeLatest(RequestTypes.UPDATE_USER_REQUEST, updateUserSettings);
}
