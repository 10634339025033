import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import React from "react";
import Colors from "src/themes/colors";
import styled from "styled-components";
import Button from "../button";

interface SearchInputProps {
  borderColor?: string; // Border color of the input
  placeholder?: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch: (value: string) => void;
}

const CustomInput = styled(Input)`
  border: 0.1rem solid ${Colors.LightGray};
  width: 500px;
  &:hover {
    border: 0.1rem solid ${Colors.Secondary};
  }
  &:blur {
    border: 0.1rem solid ${Colors.LightGray};
  }
  &:active {
    border: 0.1rem solid ${Colors.Secondary};
    outline: none;
  }
  &:focus {
    border: 0.1rem solid ${Colors.LightGray};
    outline: none;
  }
`;

const SearchBar = ({
  placeholder,
  searchValue,
  setSearchValue,
  onSearch,
}: SearchInputProps) => {
  const handleSearch = () => {
    onSearch(searchValue);
  };

  return (
    <Space.Compact style={{ width: "100%", margin: 0 }}>
      <CustomInput
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
        placeholder={placeholder}
        autoFocus
        onPressEnter={handleSearch}
      />
      <Button icon={<SearchOutlined />} onBtnClick={handleSearch} />
    </Space.Compact>
  );
};

export default SearchBar;
