import ENDPOINTS from "src/constants/endpoints";
import { CompanyStatus } from "src/constants/samples";
import { RESPONSE_TYPES } from "../../constants/response-types";
import { HttpService } from "../http";
import { prepareErrorResponse, prepareResponseObject } from "../http/response";

export class AppService extends HttpService {
  fetchAppData = async (baseAuthUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.post(`${baseAuthUrl}app`, undefined);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchCompaniesData = async (
    baseApiUrl: string,
    page: number,
    limit: number,
    status?: string,
    search: string = ""
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(
        `${baseApiUrl}company?page=${page}&limit=${limit}${
          status === "ACTIVE"
            ? `&activeOnly=${true}`
            : status === CompanyStatus.Inactive
            ? `&activeOnly=${false}`
            : ""
        }&search=${search}`
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateCompany = async (baseApiUrl: string, formData: any): Promise<any> => {
    const { id } = formData;

    delete formData.id;

    try {
      const apiResponse = await this.put(
        `${baseApiUrl}company/${id}`,
        JSON.stringify(formData)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  createCompany = async (baseApiUrl: string, formData: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        `${baseApiUrl}company`,
        JSON.stringify(formData)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateUserPassword = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.patch(
        baseApiUrl + ENDPOINTS.UPDATEPASSWORD,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchUserSettings = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.USERSETTINGS);
      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  putUserSettings = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.USERSETTINGS,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  deleteCompaniesData = async (
    baseApiUrl: string,
    id: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(`${baseApiUrl}company/${id}`);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateUser = async (baseApiUrl: string, formData: any): Promise<any> => {
    const { id } = formData;

    delete formData.id;

    try {
      const apiResponse = await this.put(
        `${baseApiUrl}user/${id}`,
        JSON.stringify(formData)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  deleteUser = async (baseApiUrl: string, id: any): Promise<any> => {
    try {
      const apiResponse = await this.delete(`${baseApiUrl}user/${id}`);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  newUser = async (baseApiUrl: string, id: any, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.CREATEUSER(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  getCompanyRoles = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(`${baseApiUrl}roles`);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  fetchUsersData = async (
    baseApiUrl: string,
    id: string,
    meta: any
  ): Promise<any> => {
    try {
      const queryString = new URLSearchParams(meta)?.toString();

      const apiResponse = await this.get(
        `${baseApiUrl}company/${id}${queryString ? `?${queryString}` : ""}`
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateUserDetails = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.USERDETAILS,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchUserDetails = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.USERDETAILS);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };

  deleteDid = async (
    baseApiUrl: string,
    userId: string,
    id: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseApiUrl + ENDPOINTS.DELETEDID(userId, id)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getReports = async (baseApiUrl: string, url: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.REPORTS + url);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getInnerReports = async (
    baseApiUrl: string,
    id: any,
    url: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(
        baseApiUrl + ENDPOINTS.REPORTSBYID(id) + url
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postSignout = async (baseApiUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.post(baseApiUrl + ENDPOINTS.SIGNOUT);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  fetchHistory = async (baseApiUrl: string, id: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseApiUrl + ENDPOINTS.HISTORY(id));

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  updateDid = async (
    baseApiUrl: string,
    id: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.put(
        baseApiUrl + ENDPOINTS.UPDATEDID(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  addDid = async (baseApiUrl: string, id: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.ADDID(id),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postForgetPassword = async (baseApiUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseApiUrl + ENDPOINTS.FORGOTPASSWORD,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  postVerifyToken = async (
    baseUrl: string,
    userId: string,
    data: any
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseUrl + ENDPOINTS.VERIFYTOKEN(userId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  resetPassword = async (
    baseUrl: string,
    data: any,
    userId: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.post(
        baseUrl + ENDPOINTS.RESETPASSWORD(userId),
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  bulkDeleteAccount = async (baseUrl: string, data: any): Promise<any> => {
    try {
      const apiResponse = await this.delete(
        baseUrl + ENDPOINTS.BULKDELETEACCOUNT,
        data
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  availabilityUserName = async (
    baseUrl: string,
    username: string
  ): Promise<any> => {
    try {
      const apiResponse = await this.get(
        baseUrl + ENDPOINTS.USERNAMEAVAILABLE(username)
      );

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  availabilityDid = async (baseUrl: string, did: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseUrl + ENDPOINTS.DIDAVAILABLE(did));

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
  getPlanRequest = async (baseUrl: string): Promise<any> => {
    try {
      const apiResponse = await this.get(baseUrl + ENDPOINTS.GETPLAN);

      return prepareResponseObject(apiResponse, RESPONSE_TYPES.SUCCESS);
    } catch (error) {
      throw prepareErrorResponse(error);
    }
  };
}
