import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchReportsByIdFailure,
  fetchReportsByIdSuccess,
} from "../slices/features/innerReports";
import { RequestTypes } from "../types";

const reportsService = new AppService();

function* getInnerReports(action: any) {
  const { payload } = action;
  const { id, data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      reportsService.getInnerReports,
      baseUrl,
      id,
      data
    );

    yield put(fetchReportsByIdSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchReportsByIdFailure({ statusCode, statusText }));
  }
}

export function* watchInnerReports() {
  yield takeLatest(RequestTypes.REPORTS_BY_ID, getInnerReports);
}
