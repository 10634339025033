import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  companyStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  companyData: null,
  permissionLevels: {
    isLoading: false,
    roles: null,
  },
  bulkDeleteState: {
    data: null,
    statusCode: null,
    statusText: null,
  },
  bulkDeleteError: {
    isLoading: false,
    error: false,
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState: INITIAL_STATE,
  reducers: {
    fetchCompaniesData: (state, { payload }) => {
      state.companyStates.isLoading = true;
      payload;
    },
    fetchCompaniesDataSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      companyData: action.payload.data,
    }),
    fetchCompaniesDataFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),

    deleteCompanyRecord: (state, { payload: id }) => {
      state.companyStates.isLoading = true;
      id;
    },

    deleteCompanyRecordSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),

    deleteCompanyRecordFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),

    fetchPermissionLevels: (state) => {
      state.permissionLevels.isLoading = true;
    },

    fetchPermissionLevelsSuccess: (state, action) => {
      state.permissionLevels.isLoading = false;
      state.permissionLevels.roles = action.payload;
    },
    fetchPermissionLevelsFailure: (state) => {
      state.permissionLevels.isLoading = false;
    },
    fetchBulkDeleteAccounts: (state) => {
      state.bulkDeleteError.isLoading = true;
    },
    fetchBulkDeleteAccountsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      bulkDeleteState: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload.data,
      },
      bulkDeleteError: {
        error: false,
        isLoading: false,
      },
    }),
    fetchBulkDeleteAccountsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      bulkDeleteState: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: null,
      },
      bulkDeleteError: {
        error: true,
        isLoading: false,
      },
    }),
    clearCompanies: (state) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
        error: null,
      },

      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      companyData: null,
    }),
  },
});

export const {
  fetchCompaniesData,
  fetchCompaniesDataSuccess,
  fetchCompaniesDataFailure,
  deleteCompanyRecord,
  deleteCompanyRecordSuccess,
  deleteCompanyRecordFailure,
  fetchPermissionLevels,
  fetchPermissionLevelsSuccess,
  fetchPermissionLevelsFailure,
  fetchBulkDeleteAccounts,
  fetchBulkDeleteAccountsFailure,
  fetchBulkDeleteAccountsSuccess,
  clearCompanies,
} = companySlice.actions;
export const companyReducer = companySlice.reducer;
