import { DeleteOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Limit } from "src/constants/endpoints";
import { CompanyStatus } from "src/constants/samples";
import { getSearchTerm } from "src/store/selectors/features/app";
import {
  deleteCompanyRecord,
  fetchCompaniesData,
} from "src/store/slices/features/company";
import {
  capitalizeFirstLetter,
  turnicatetext,
} from "src/utils/helper-functions";
import Button from "../button";
import styles from "./customTable.module.scss";

const { Option } = Select;

interface CustomTableProps {
  columns: any[];
  data: any[];
  setShowUsersList?: any;
  setShowCreateAccountForm?: any;
  setRowSelected?: any;
  loading: boolean;
  setSelectedAccounts?: any;
  isLoading?: boolean;
  totalPages?: number;
  onChangePage: (e: number) => void;
  showActive?: boolean;
}

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  setRowSelected = () => "",
  setSelectedAccounts,
  totalPages,
  onChangePage,
  showActive,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const searchTerm = useSelector(getSearchTerm);

  useEffect(() => {
    if (selectedRowKeys.length && setRowSelected) {
      setSelectedAccounts(selectedRowKeys);
      setRowSelected(true);
    } else {
      setRowSelected(false);
    }
  }, [selectedRowKeys]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDelete = (record: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      content: "This action cannot be undone.",
      onOk() {
        const params: {
          page: number;
          limit: string;
          search: string;
          status?: string;
        } = {
          page: 1,
          limit: Limit,
          search: searchTerm.replace(/&/g, "%"),
        };

        if (!showActive) {
          params["status"] = CompanyStatus.Active;
        }

        dispatch(
          deleteCompanyRecord({
            id: record.id,
            cbSuccess: () => {
              setRowSelected(false);
              dispatch(fetchCompaniesData(params));
            },
          })
        );
      },
    });

    if (record.id === editingRow) {
      setEditingRow(null);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onCompanyClick = (record: any) => {
    navigate(`/accounts/${record.id}`);
  };

  const updatedColumns = columns.map((column: any) => {
    if (column.dataIndex === "status") {
      return {
        ...column,
        render: (text: string, record: any) =>
          editingRow === record.id ? (
            <Form.Item
              className={styles.flex_align_center}
              name="companyStatus"
              rules={[{ required: true, message: "Company Status" }]}
            >
              <Select placeholder="Active">
                {Object.values(CompanyStatus).map((timezone, index) => (
                  <Option key={index} value={timezone}>
                    {capitalizeFirstLetter(
                      timezone?.toString()?.toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <span
              className={`${styles.flex_align_center}  ${
                record.status !== CompanyStatus.Inactive
                  ? ""
                  : styles.text_secondary
              }`}
            >
              <div
                className={`${styles.dot} ${
                  record.status === CompanyStatus.Active
                    ? styles.bg_success
                    : record.status === CompanyStatus.Inactive
                    ? styles.bg_secondary
                    : styles.bg_pending
                }`}
              />
              {capitalizeFirstLetter(text?.toString()?.toLocaleLowerCase())}
            </span>
          ),
      };
    }

    if (column.dataIndex === "companyname") {
      return {
        ...column,
        render: (text: string, record: any) =>
          record.id === editingRow ? (
            <span
              className={`${
                record.status === CompanyStatus.Active
                  ? styles.text_primary
                  : styles.text_secondary
              } `}
            >
              <Form.Item
                name="companyName"
                rules={[{ required: true, message: "enter company name" }]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                textWrap: "nowrap",
              }}
              className={`${
                record.status === CompanyStatus.Inactive
                  ? styles.text_secondary
                  : styles.text_primary
              } ${styles.flex_align_center} `}
            >
              {turnicatetext(24, text)}
              {record.status === CompanyStatus.Pending && (
                <div className={`${styles.big_dot} ${styles.bg_warn}`} />
              )}
            </span>
          ),
      };
    }

    if (
      column.dataIndex === "id" ||
      column.dataIndex === "category" ||
      column.dataIndex === "monthlyusage" ||
      column.dataIndex === "created" ||
      column.dataIndex === "lastactivity"
    ) {
      return {
        ...column,
        render: (text: string, record: any) => (
          <span
            className={`${
              record.status === CompanyStatus.Active
                ? ""
                : styles.text_secondary
            } `}
          >
            {capitalizeFirstLetter(text?.toString()?.toLocaleLowerCase()) ||
              "-"}
          </span>
        ),
      };
    }

    return column;
  });

  const rowProps = (record: any) => {
    return {
      onClick: () => onCompanyClick(record),
    };
  };

  return (
    <Form
      style={{
        height: "100%",
        display: "flex",
        width: "100%",
      }}
    >
      <Table
        style={{
          height: "100%",
          width: "100%",
        }}
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        pagination={{
          total: totalPages,
          onChange: (page: number) => {
            onChangePage(page);
          },
          showSizeChanger: false,
        }}
        className={styles.table_wrapper}
        onRow={(record) => ({
          ...rowProps(record),
        })}
        rowClassName={(record) =>
          selectedRowKeys?.includes(record.id) ? "selected" : "unselected"
        }
        columns={[
          ...updatedColumns,
          {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 80,
            render: (_, record) => (
              <Space size="middle" align="center">
                <Button
                  shape="circle"
                  title=""
                  icon={<DeleteOutlined />}
                  onBtnClick={(e) => {
                    e.stopPropagation();
                    handleDelete(record);
                  }}
                ></Button>
              </Space>
            ),
          },
        ]}
        dataSource={data}
      />
    </Form>
  );
};

export default CustomTable;
