import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  signoutState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  signoutData: null,
};

export const signoutSlice = createSlice({
  name: "signout",
  initialState: INITIAL_STATE,
  reducers: {
    postSignout: (state) => {
      state.signoutState.isLoading = true;
    },
    postSignoutSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      signoutState: {
        ...state.signoutState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      signoutData: action.payload.data,
    }),
    postSignoutFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      signoutState: {
        ...state.signoutState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const { postSignoutSuccess, postSignout, postSignoutFailure } =
signoutSlice.actions;
export const signoutReducer = signoutSlice.reducer;
