import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
} from "../slices/features/userDetails";
import { RequestTypes } from "../types";

const useDetailsService = new AppService();

function* fetchUserDetailsSaga() {
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      useDetailsService.fetchUserDetails,
      baseUrl
    );

    yield put(fetchUserDetailsSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchUserDetailsFailure({ statusCode, statusText }));
  }
}

export function* watchfetchUserDetails() {
  yield takeLatest(
    RequestTypes.FETCH_USER_DETAIL_REQUEST,
    fetchUserDetailsSaga
  );
}
