import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  availabilityState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
  apiStatusDid: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const availabilitySlice = createSlice({
  name: "availability",
  initialState: INITIAL_STATE,
  reducers: {
    fetchAvailablityDid: (state) => {
      state.availabilityState.isLoading = true;
    },
    fetchAvailablityDidSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: null,
        isLoading: false,
      },
      apiStatusDid: {
        ...state.apiStatusDid,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchAvailablityDidFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatusDid: {
        ...state.apiStatusDid,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchAvailablityUsername: (state) => {
      state.availabilityState.isLoading = true;
    },
    fetchAvailablityUsernameSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchAvailablityUsernameFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  fetchAvailablityDid,
  fetchAvailablityDidFailure,
  fetchAvailablityDidSuccess,
  fetchAvailablityUsername,
  fetchAvailablityUsernameFailure,
  fetchAvailablityUsernameSuccess,
} = availabilitySlice.actions;
export const availabilityReducer = availabilitySlice.reducer;
