import type { FormInstance } from "antd";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTimezoneSelect } from "react-timezone-select";
import {
  CompanyStatus,
  categoryOptions,
  labelStyle,
  timezones,
} from "src/constants/samples";
import { getPlanData } from "src/store/selectors/features/plans";
import RequestAppAction from "src/store/slices/appActions";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import styles from "../createAccount.module.scss";
const { Title } = Typography;
const { Option } = Select;

interface Props {
  form?: FormInstance;
}

const { Text } = Typography;

const CreateAccountForm = ({ form }: Props) => {
  const dispatch = useDispatch();
  const plans: any = useSelector(getPlanData);
  const [checked, setIsChecked] = useState(true);

  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    dispatch(initiateFetchRoles());
    form?.setFieldValue("billedExternally", true);
    dispatch(
      RequestAppAction.fetchPlan({
        cbSuccess: (res: any) => {
          if (form) {
            form.setFieldValue("plan", res?.data[0]?.id);
          }
        },
      })
    );
  }, []);

  return (
    <div className={styles.account_details}>
      <Space className={styles.account_details_header} align="center">
        <Title level={5} style={{ margin: 0 }}>
          Account Details
        </Title>
      </Space>

      <div className={styles.form_wrapper}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[{ required: true, message: "Please enter company name" }]}
            >
              <Input placeholder="Enter company name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="timezone"
              label="Timezone"
              rules={[{ required: true, message: "Please select timezone" }]}
            >
              <Select placeholder="Select Timezone">
                {options?.map((timezone, index) => (
                  <Option key={index} value={timezone.label}>
                    {timezone.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="category"
              label="Category"
              initialValue={categoryOptions[0]}
              rules={[{ required: true, message: "Category" }]}
            >
              <Select placeholder="Select Category">
                {categoryOptions.map((category, index) => (
                  <Option key={index} value={category}>
                    {capitalizeFirstLetter(
                      category?.toString()?.toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="companyStatus"
              label="Company Status"
              initialValue={capitalizeFirstLetter(
                CompanyStatus.Active.toLocaleLowerCase().toString()
              )}
              rules={[{ required: true, message: "Company Status" }]}
            >
              <Select placeholder="active">
                {Object.values(CompanyStatus).map((timezone, index) => (
                  <Option key={index} value={timezone}>
                    {capitalizeFirstLetter(
                      timezone?.toString()?.toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="plan"
              label="Plan"
              rules={[{ required: true, message: "Plan" }]}
            >
              <Select placeholder="Select Plan">
                {plans?.length > 0 &&
                  plans?.map((category: any, index: number) => (
                    <Option key={index} value={category?.id}>
                      {capitalizeFirstLetter(category?.stripeProductName || "")}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="notes"
              label="Notes"
              rules={[{ message: "Enter Details" }]}
            >
              <Input placeholder="Enter company name" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              style={{ margin: 0 }}
              valuePropName="checked"
              name="billedExternally"
            >
              <Checkbox
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
                checked={checked}
              >
                <Text>Billed externally</Text>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateAccountForm;
