export const baseUrl = process.env.REACT_APP_BASE_URL;

export const Limit = `10`;

export const PASSWORDREGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,30}$/;

const ENDPOINTS = {
  LOGIN: `user/signin`,
  UPDATEPASSWORD: `user/password/update`,
  USERSETTINGS: "user/settings",
  FETCHCOMAPNT: "company",
  USERDETAILS: "user",
  DELETEDID: (userID: string | number, id: string | number) =>
    `user/${userID}/did/${id}`,
  REPORTS: "reports",
  REPORTSBYID: (id: string | number) => `reports/${id}`,
  HISTORY: (id: string | number) => `company/${id}/activity-history`,
  UPDATEDID: (id: string | number) => `did/${id}`,
  ADDID: (id: string | number) => `user/${id}/did`,
  CREATEUSER: (id: string | number) => `company/${id}/users`,
  SIGNOUT: "user/signout",
  FORGOTPASSWORD: "user/forgot-password",
  VERIFYTOKEN: (id: string) => `user/verify-token/${id}`,
  RESETPASSWORD: (id: string) => `user/reset-password/${id}`,
  USERNAMEAVAILABLE: (username: string) =>
    `user/username-availability/${username}`,
  DIDAVAILABLE: (did: string) => `user/did-availability/${did}`,
  BULKDELETEACCOUNT: "company/bulk/delete",
  GETPLAN: "stripe-products",
};

export default ENDPOINTS;
