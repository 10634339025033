import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  userSettingsStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  userSettings: null,
};

export const UserSettingsSlice = createSlice({
  name: "userSettings",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUserSettings: (state) => {
      state.userSettingsStates.isLoading = true;
    },
    fetchUserSettingsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      userSettingsStates: {
        ...state.userSettingsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      userSettings: action.payload.data,
    }),
    fetchUserSettingsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      userSettingsStates: {
        ...state.userSettingsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    putUserSettings: (state) => {
      state.userSettingsStates.isLoading = true;
    },
    putUserSettingsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      userSettingsStates: {
        ...state.userSettingsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      userSettings: action.payload.data,
    }),
    putUserSettingsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      userSettingsStates: {
        ...state.userSettingsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    cleaerUserSettings: (state) => ({
      ...state,
      userSettingsStates: {
        ...state.userSettingsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      userSettings: null,
    }),
  },
});

export const {
  fetchUserSettings,
  fetchUserSettingsSuccess,
  fetchUserSettingsFailure,
  putUserSettings,
  putUserSettingsSuccess,
  putUserSettingsFailure,
} = UserSettingsSlice.actions;
export const userSettingsReducer = UserSettingsSlice.reducer;
