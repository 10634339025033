import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { LANGUAGE } from "constants/language";
// import { ROUTES } from "constants/navigation-routes";

interface IAppAction {
  language?: string;
  baseUrl?: string;
  activeScreen?: string;
  isLoading?: boolean;
  error?: any;
  statusCode?: any;
  statusText?: any;
}

const INITIAL_STATE = {
  // language: LANGUAGE.ENGLISH,
  baseUrl: process.env.REACT_APP_BASE_URL || "",
  // activeScreen: ROUTES.LOGIN,
  validationStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  searchTerm: "",
};

export const appFeatureSlice = createSlice({
  name: "app",
  initialState: INITIAL_STATE,
  reducers: {
    toggleLoading: (state) => {
      state.validationStates.isLoading = true;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    fetchAppData: (state) => {
      state.validationStates.isLoading = true;
    },
    fetchAppDataSuccess: (state, action: PayloadAction<IAppAction>) => ({
      // ! do you update the data here or in entity?
      ...state,
      validationStates: {
        ...state.validationStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchAppDataFailure: (state, action: PayloadAction<IAppAction>) => ({
      ...state,
      validationStates: {
        ...state.validationStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  toggleLoading,
  fetchAppData,
  fetchAppDataSuccess,
  fetchAppDataFailure,
  setSearchTerm,
} = appFeatureSlice.actions;
export const appFeatureReducer = appFeatureSlice.reducer;
