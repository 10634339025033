import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const historySelector = (state: any) => state.features.history;

export const getBaseUrl = createSelector(historySelector, (app) => app.baseUrl);

export const getHistoryData = createSelector(
  historySelector,
  (app) => app.historyData
);

export const getHistoryStates = createSelector(
  historySelector,
  (app) => app.historyStates
);

export const getCompanyLoadingState = createSelector(
    getHistoryStates,
  (historyState) => historyState.isLoading
);
