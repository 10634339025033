import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  reportState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  reportData: null,
  permissionLevels: {
    isLoading: false,
    roles: null,
  },
};

export const reportByIdSlice = createSlice({
  name: "reportById",
  initialState: INITIAL_STATE,
  reducers: {
    fetchReportsById: (state) => {
      state.reportState.isLoading = true;
    },
    fetchReportsByIdSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      reportState: {
        ...state.reportState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      reportData: action.payload.data,
    }),
    fetchReportsByIdFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      reportState: {
        ...state.reportState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearReportsById: (state) => ({
      ...state,
      reportState: {
        ...state.reportState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      reportData: null,
    }),
  },
});

export const {
  fetchReportsById,
  fetchReportsByIdSuccess,
  fetchReportsByIdFailure,
  clearReportsById,
} = reportByIdSlice.actions;
export const reportByIdReducer = reportByIdSlice.reducer;
