import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchAppDataFailure } from "../slices/features/app";
import { fetchReportsSuccess } from "../slices/features/reports";
import { RequestTypes } from "../types";

const reportsService = new AppService();

function* getReports(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      reportsService.getReports,
      baseUrl,
      data
    );

    yield put(fetchReportsSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchAppDataFailure({ statusCode, statusText }));
  }
}

export function* watchReports() {
  yield takeLatest(RequestTypes.REPORTS_REQUEST, getReports);
}
