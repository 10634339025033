import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  updateCompanyFailure,
  updateCompanySuccess,
} from "../slices/features/updateCompany";
import { RequestTypes } from "../types";

const updateCompanyService = new AppService();

function* updateCompany(action: any) {
  const { payload } = action;

  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      updateCompanyService.updateCompany,
      baseUrl,
      data
    );

    toast.success("Company updated successfully!");

    yield put(updateCompanySuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(updateCompanyFailure({ statusCode, statusText }));
  }
}

export function* watchUpdateCompany() {
  yield takeLatest(RequestTypes.UPDATE_COMPANY, updateCompany);
}
