import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchUsersDataFailure,
  fetchUsersDataSuccess,
} from "../slices/features/users";
import { RequestTypes } from "../types";

const usersService = new AppService();

function* fetchUsers(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const query: { activeOnly?: boolean; search?: string } = {};

    if (payload?.hasOwnProperty("activeOnly") && payload?.activeOnly) {
      query["activeOnly"] = true;
    }

    if (payload?.search) {
      query["search"] = payload?.search;
    }

    const response: AxiosResponse<any> = yield call(
      usersService.fetchUsersData,
      baseUrl,
      id,
      query
    );

    yield put(fetchUsersDataSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);
    payload?.cbFailure && payload?.cbFailure();

    yield put(fetchUsersDataFailure({ statusCode, statusText }));
  }
}

export function* watchUsers() {
  yield takeLatest(RequestTypes.FETCH_USERS, fetchUsers);
}
