import { DownloadOutlined } from "@ant-design/icons";
import { DatePicker, FloatButton, Space } from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "src/components/button";
import ReportsTable from "src/components/reports-table";
import ENDPOINTS, { Limit, baseUrl } from "src/constants/endpoints";
import { DateFormat } from "src/constants/samples";
import { getSearchTerm } from "src/store/selectors/features/app";
import {
  getinnerReportLoadingState,
  getinnerReportsData,
} from "src/store/selectors/features/reportById";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import { clearReportsById } from "src/store/slices/features/innerReports";

const { RangePicker } = DatePicker;

const reportsColumns: any = [
  {
    title: "User Name",
    dataIndex: "username",
    key: "username",
    fixed: "left",
  },
  {
    title: "Traffic Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Sender",
    dataIndex: "sender",
    key: "sender",
  },
  {
    title: "Receiver",
    dataIndex: "receiver",
    key: "receiver",
  },
  {
    title: "Usage Count",
    dataIndex: "usageCount",
    key: "usageCount",
    sorter: (a: any, b: any) => a.usageCount - b.usageCount,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Time Stamp",
    dataIndex: "timeStamp",
    key: "timeStamp",
    width: 280,
    sorter: (a: any, b: any) =>
      new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime(),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Time Zone",
    dataIndex: "timeZone",
    key: "timeZone",
  },
];

const ReportsById: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const reportById = useSelector(getinnerReportsData);
  const [currentPage, setCurrentPage] = useState(1);
  const [myRange, setMyRange] = React.useState<any>([]);
  const searchTerm = useSelector(getSearchTerm);
  const isLoading = useSelector(getinnerReportLoadingState);

  function buildApiUrl({ page, search, downloadAs, startDate, endDate }: any) {
    const limit = Limit;

    const queryParams = new URLSearchParams();

    page && queryParams.append("page", page);
    downloadAs && queryParams.append("downloadAs", downloadAs);
    startDate && queryParams.append("startDate", startDate);
    endDate && queryParams.append("endDate", endDate);
    search && queryParams.append("search", search);
    queryParams.append("limit", limit);

    return `?${queryParams?.toString()}`;
  }

  useEffect(() => {
    const query = {
      page: currentPage,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
    };

    dispatch(
      RequestAppAction.fetchReportsById({
        id: state.id,
        data: buildApiUrl(query),
      })
    );

    return () => {
      dispatch(clearReportsById());
      dispatch(setSearchTerm(""));
    };
  }, []);

  const changePage = (page: any) => {
    const query = {
      page: page,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
    };

    dispatch(
      RequestAppAction.fetchReportsById({
        id: state.id,
        data: buildApiUrl(query),
      })
    );
    setCurrentPage(page);
  };

  const GenerateRangeData = () => {
    if (myRange.length > 1) {
      const query = {
        page: currentPage,
        search: searchTerm,
        startDate: myRange.length > 1 ? myRange[0] : null,
        endDate: myRange.length > 1 ? myRange[1] : null,
      };

      dispatch(
        RequestAppAction.fetchReportsById({
          id: state.id,
          data: buildApiUrl(query),
        })
      );
    }
  };

  useEffect(() => {
    if (myRange.length > 1) {
      if (myRange[0] === "" && myRange[1] === "") {
        setMyRange([]);

        const query = {
          page: currentPage,
          search: searchTerm,
        };

        dispatch(
          RequestAppAction.fetchReportsById({
            id: state.id,
            data: buildApiUrl(query),
          })
        );
      }
    }
  }, [myRange]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      const query = {
        page: currentPage,
        search: searchTerm,
        startDate: myRange.length > 1 ? myRange[0] : null,
        endDate: myRange.length > 1 ? myRange[1] : null,
      };

      dispatch(
        RequestAppAction.fetchReportsById({
          id: state.id,
          data: buildApiUrl(query),
        })
      );
    }, 800);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [searchTerm]);

  const download = (type: string) => {
    const query = {
      page: currentPage,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
      downloadAs: type,
    };

    const url = baseUrl + ENDPOINTS.REPORTSBYID(state.id) + buildApiUrl(query);

    return url;
  };

  const updatedColumns = reportsColumns.map((column: any) => {
    if (column.dataIndex === "timeStamp") {
      return {
        ...column,
        render: (text: string) => (
          <span>{format(new Date(text), DateFormat) || "-"}</span>
        ),
      };
    }

    if (column.dataIndex === "category") {
      return {
        ...column,
        render: (text: string) => <span>{text || "-"}</span>,
      };
    }

    return column;
  });

  return (
    <div style={{ padding: "1rem 3rem", height: "100%", position: "relative" }}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.2rem 1rem 1rem 1rem",
          }}
        >
          <Space
            align="center"
            style={{
              margin: 0,
              padding: 0,
              alignItems: "center",
              display: "flex",
              gap: 20,
              position: "relative",
            }}
          >
            <RangePicker onChange={(_, b) => setMyRange(b)} />
            <Button
              onBtnClick={() => GenerateRangeData()}
              title="Generate"
              icon
            />
          </Space>
          <Space
            align="center"
            style={{
              margin: 0,
              padding: 0,
              alignItems: "center",
              display: "flex",
              gap: 20,
              position: "relative",
            }}
          >
            <a href={download("CSV")}>
              <div>
                <FloatButton
                  shape="circle"
                  style={{ top: -22, right: 0, position: "absolute" }}
                  icon={<DownloadOutlined />}
                />
              </div>
            </a>
          </Space>
        </Space>

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            overflowY: "auto",
          }}
        >
          <ReportsTable
            spin={isLoading}
            columns={updatedColumns}
            data={reportById?.items ? reportById?.items : []}
            totalPages={reportById?.meta?.totalCount}
            onChangePage={(page: any) => {
              changePage(page);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsById;
