import axios from "axios";
import { persistor } from "src/App";
import { REFRESH_TOKEN_HEADER } from "src/constants/auth";
import { ROUTES } from "src/constants/navigation-routes";
import { RESPONSE_TYPES, STATUS_CODES } from "../../constants/response-types";
import { LocalStorageService } from "../local-storage";

const localStorageService = new LocalStorageService();

axios.interceptors.request.use(
  async function (req) {
    return req;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    if (response.headers[REFRESH_TOKEN_HEADER]) {
    }

    return response;
  },
  async (error) => {
    if (
      axios.isCancel(error) ||
      error?.message === RESPONSE_TYPES.NETWORK_ERROR ||
      error?.response?.status === 408 ||
      error?.code === "ECONNABORTED"
    ) {
      return Promise.reject({ noInternet: true });
    }

    if (error?.response?.status === STATUS_CODES.UNAUTHORIZED) {
      await localStorageService.remove("authToken");
      await localStorageService.remove("userData");
      await localStorageService.remove("user");
      persistor.pause();
      await persistor.flush();
      await persistor.purge().then(() => {
        if (window.location.pathname !== ROUTES.LOGIN) {
          window.location.pathname = ROUTES.LOGIN;
        }
      });
    }

    return Promise.reject(error);
  }
);
