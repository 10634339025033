import { Switch as AntdSwitch, Form } from "antd";
import React, { useEffect, useState } from "react";
import Colors from "src/themes/colors";
import styled from "styled-components";
import styles from "./switch.module.scss";

// Define your custom styles for the switch
const StyledSwitch = styled(AntdSwitch)`
  background-color: ${Colors.Gray};
  background-color: ${(props) =>
    props.checked ? Colors.Secondary : Colors.Gray};
`;

interface SwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  label: string;
  checkedText?: string;
  unCheckedText?: string;
  loading?: boolean;
}

const Switch: React.FC<SwitchProps> = ({
  onChange,
  label,
  checkedText = "",
  unCheckedText = "",
  checked = false,
  loading,
}) => {
  const [isChecked, setChecked] = useState<boolean>(false);

  const handleChange = (checkedValue: boolean) => {
    setChecked(checkedValue);

    if (onChange) {
      onChange(checkedValue);
    }
  };

  useEffect(() => {
    setChecked(checked);
  }, []);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <Form.Item
      initialValue={{
        isChecked,
      }}
      label={label}
      className={styles.switch_form_wrapper}
    >
      <StyledSwitch
        loading={loading}
        checked={isChecked}
        onChange={handleChange}
        style={{ backgroundColor: isChecked ? Colors.Secondary : Colors.Gray }}
        checkedChildren={checkedText}
        unCheckedChildren={unCheckedText}
      />
    </Form.Item>
  );
};

export default Switch;
