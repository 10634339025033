import { RequestTypes } from "../types";

class RequestAppAction {
  static updatePassword(payload: any) {
    return {
      type: RequestTypes.UPDATE_PASSWORD_REQUEST,
      payload,
    };
  }
  static fetchUserSettings(payload: { cbSuccess: () => void }) {
    return {
      type: RequestTypes.FETCH_USER_REQUEST,
      payload,
    };
  }
  static UpdateUserSettings(data: any) {
    return {
      type: RequestTypes.UPDATE_USER_REQUEST,
      payload: data,
    };
  }
  static UpdateUserDetails(data: any) {
    return {
      type: RequestTypes.UPDATE_USER_DETAIL_REQUEST,
      payload: data,
    };
  }
  static fetchUserDetails() {
    return {
      type: RequestTypes.FETCH_USER_DETAIL_REQUEST,
    };
  }
  static deleteDid(data: any) {
    return {
      type: RequestTypes.DELETE_DID_REQUEST,
      payload: data,
    };
  }
  static fetchReports(data: any) {
    return {
      type: RequestTypes.REPORTS_REQUEST,
      payload: data,
    };
  }
  static fetchHistory(data: string) {
    return {
      type: RequestTypes.HISTORY_REQUEST,
      payload: data,
    };
  }
  static addNewUser(data: any) {
    return {
      type: RequestTypes.ADD_USER_REQUEST,
      payload: data,
    };
  }
  static fetchReportsById(data: any) {
    return {
      type: RequestTypes.REPORTS_BY_ID,
      payload: data,
    };
  }
  static userSignOut(data: any) {
    return {
      type: RequestTypes.SIGN_OUT_REQUEST,
      payload: data,
    };
  }
  static updateDid(data: any) {
    return {
      type: RequestTypes.UPDATE_DID_REQUEST,
      payload: data,
    };
  }
  static addDid(data: any) {
    return {
      type: RequestTypes.ADD_DID_REQUEST,
      payload: data,
    };
  }
  static postforgetPassword(data?: any) {
    return {
      type: RequestTypes.POST_FORGET_PASSWORD,
      payload: data,
    };
  }
  static verifyToken(payload?: any) {
    return {
      type: RequestTypes.POST_VERIFY_TOKEN,
      payload,
    };
  }
  static resetPassword(payload?: any) {
    return {
      type: RequestTypes.POST_RESET_PASSWORD,
      payload,
    };
  }
  static bulkDeleteAccounts(payload?: any) {
    return {
      type: RequestTypes.BULK_DELETE_ACCOUNTS,
      payload,
    };
  }
  static verifyUser(payload?: any) {
    return {
      type: RequestTypes.AVAILABILITY_USERNAME_REQUEST,
      payload,
    };
  }
  static verifyDid(payload?: any) {
    return {
      type: RequestTypes.AVAILABILITY_DID_REQUEST,
      payload,
    };
  }
  static fetchPlan(payload?: any) {
    return {
      type: RequestTypes.GET_PLAN_REQUEST,
      payload,
    };
  }
}
export default RequestAppAction;
