import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const rolesSelector = (state: TReduxState) => state.features.roles;

export const getBaseUrl = createSelector(rolesSelector, (app) => app.baseUrl);

export const getRolesData = createSelector(
  rolesSelector,
  (app) => app.rolesData
);

export const getRolesState = createSelector(
  rolesSelector,
  (app) => app.rolesStates
);

export const getRolesLoading = createSelector(
  getRolesState,
  (rolesStates) => rolesStates.isLoading
);