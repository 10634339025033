import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { AppService } from "src/services/app";

import toast from "react-hot-toast";
import { getBaseUrl } from "../selectors/features/app";
import { putPasswordSuccess } from "../slices/features/updatePassword";
import { RequestTypes } from "../types";

const appService = new AppService();

function* updatePassword(action: any) {
  const { payload } = action;
  const { currentPassword, newPassword } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    const response: AxiosResponse<any> = yield call(
      appService.updateUserPassword,
      baseUrl,
      data
    );

    toast.success("Password updated Successfully");

    yield put(putPasswordSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    payload?.cbFailure && payload?.cbFailure();

    yield put(putPasswordSuccess({ statusCode, statusText }));
  }
}

export function* watchUpdatePassword() {
  yield takeLatest(RequestTypes.UPDATE_PASSWORD_REQUEST, updatePassword);
}
