import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/did";
import {
  fetchResetPasswordFailure,
  fetchResetPasswordSuccess,
} from "../slices/features/reset-password";
import { RequestTypes } from "../types";

const appService = new AppService();

function* resetPassword(action: any) {
  const { payload } = action;
  const { data, userId } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.resetPassword,
      baseUrl,
      data,
      userId
    );

    yield put(fetchResetPasswordSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (errors: any) {
    const { statusCode, statusText } = errors;

    const message = errors?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchResetPasswordFailure({ statusCode, statusText }));
  }
}

export function* watchResetPassword() {
  yield takeLatest(RequestTypes.POST_RESET_PASSWORD, resetPassword);
}
