import { User } from "./types";

export const usersList: User[] = [
  {
    userName: "Brian P",
    contactName: "Brian Presement",
    email: "brian@test.com",
    additionalEmail: "123@brian.com",
    permissionLevel: "Super Admin (Limited)",
    status: "Active",
    currentMonthUsage: "500/2500",
  },
  {
    userName: "Alice J",
    contactName: "Alice Johnson",
    email: "alice@test.com",
    additionalEmail: "alice@company.com",
    permissionLevel: "Admin",
    status: "Inactive",
    currentMonthUsage: "800/2500",
  },
  {
    userName: "John D",
    contactName: "John Doe",
    email: "john@test.com",
    additionalEmail: "john@company.com",
    permissionLevel: "User",
    status: "Requested",
    currentMonthUsage: "100/2500",
  },
];

export const timezoneOptions = ["EST", "UTC"];

export const sortingBy = ["INBOUND", "OUTBOUND"];

export const categoryOptions = ["ORGANIC", "UNITE", "RESELLER"];

export enum CompanyStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Inactive = "INACTIVE",
}

export const labelStyle = "original";

export const timezones = {
  "America/Dawson": "Dawson, Yukon",
  "America/Edmonton": "Mountain Time",
  "America/Regina": "Saskatchewan",
  "America/Winnipeg": "Central Time",
  "America/Toronto": "Eastern Time",
  "America/St_Johns": "Newfoundland and Labrador",
};

export const DateFormat = "MMMM d, yyyy h:mm aaaa";
