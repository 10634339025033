import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  userDetailsStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  userDetails: null,
};

export const UserDetailsSlice = createSlice({
  name: "userDetails",
  initialState: INITIAL_STATE,
  reducers: {
    UpdateUserDetails: (state) => {
      state.userDetailsStates.isLoading = true;
    },
    putUserDetailsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      userDetailsStates: {
        ...state.userDetailsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      userDetails: action.payload.data,
    }),
    putUserDetailsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      userDetailsStates: {
        ...state.userDetailsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchUserDetails: (state) => {
      state.userDetailsStates.isLoading = true;
    },
    fetchUserDetailsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      userDetailsStates: {
        ...state.userDetailsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      userDetails: action.payload.data,
    }),
    fetchUserDetailsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      userDetailsStates: {
        ...state.userDetailsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearUserDetails: (state) => ({
      ...state,
      userDetailsStates: {
        ...state.userDetailsStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      userDetails: null,
    }),
  },
});

export const {
  UpdateUserDetails,
  putUserDetailsSuccess,
  putUserDetailsFailure,
  fetchUserDetails,
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
  clearUserDetails,
} = UserDetailsSlice.actions;
export const userDetailsReducer = UserDetailsSlice.reducer;
