import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { deleteUserFailure, deleteUserSuccess } from "../slices/features/users";
import { RequestTypes } from "../types";

const usersService = new AppService();

function* deleteUser(action: any) {
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      usersService.deleteUser,
      baseUrl,
      action.payload
    );

    toast.success("user deleted successfully");

    yield put(deleteUserSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(deleteUserFailure({ statusCode, statusText }));
  }
}

export function* watchDelete() {
  yield takeLatest(RequestTypes.DELETE_USER, deleteUser);
}
