import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const planSelector = (state: TReduxState) => state.features.plan;

export const getBaseUrl = createSelector(planSelector, (app) => app.baseUrl);

export const getPlanData = createSelector(
  planSelector,
  (app) => app.apiStatus.data
);

export const getPlanState = createSelector(
  planSelector,
  (app) => app.planState
);

export const getPlanLoadingState = createSelector(
  getPlanState,
  (plan) => plan.isLoading
);
