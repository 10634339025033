import React from "react";
import CreateAccount from "../../../components/create-account";

export const CreateNewAccount: React.FC = () => {
  return (
    <div style={{ height: "100%" }}>
      <CreateAccount />
    </div>
  );
};
