export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const turnicatetext = (maxLength: number, text: string) => {
  const truncatedText =
    text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  return truncatedText;
};
