import { BellOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Limit } from "src/constants/endpoints";
import { getCompanyData } from "src/store/selectors/features/company";
import { fetchCompaniesData } from "src/store/slices/features/company";
import styles from "./styles.module.scss";

interface HeaderProps {
  onClick?: () => void;
  onLogout?: () => void;
  user?: string;
}

const { Text } = Typography;

const Header = ({ onClick, onLogout, user }: HeaderProps) => {
  const companyData: any = useSelector(getCompanyData);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      page: 1,
      limit: Limit,
    };

    if (user) {
      dispatch(fetchCompaniesData(data));
    }
  }, [user]);

  return (
    <Space
      align="center"
      style={{
        backgroundColor: "#1c4da1",
        width: "100%",
        height: "4rem",
        position: "sticky",
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <img
        onClick={onClick}
        style={{
          padding: "1.8rem 2rem 1.5rem 1rem",
          width: "8rem",
        }}
        src={require("../../assets/images/textmeanywhere.png")}
      />
      {user && (
        <Space
          style={{
            color: "white",
            padding: "0 2rem",
            margin: "0rem",
          }}
        >
          <div className={styles.bell_div}>
            {companyData?.meta?.totalPending ? (
              <div className={styles.red_div}>
                <Text className={styles.text}>
                  {companyData?.meta?.totalPending}
                </Text>
              </div>
            ) : null}
            <BellOutlined />
          </div>
          <Text
            style={{
              color: "white",
              padding: "0 1rem 0rem 1rem",
              margin: 0,
              textTransform: "capitalize",
              fontSize: "1rem",
            }}
          >
            {user}
          </Text>

          <Space
            style={{
              color: "white",
              margin: 0,
            }}
          >
            <Text
              style={{
                fontSize: "1rem",
                cursor: "pointer",
                color: "white",
                fontWeight: "500",
              }}
              onClick={onLogout}
            >
              logout
            </Text>
          </Space>
        </Space>
      )}
    </Space>
  );
};

export default Header;
