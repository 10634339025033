import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  newUserStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  newUserData: null,
};

export const newUsersSlice = createSlice({
  name: "newUser",
  initialState: INITIAL_STATE,
  reducers: {
    addNewUser: (state, { payload: id }) => {
      state.newUserStates.isLoading = true;
      id;
    },
    addNewUserSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      newUserStates: {
        ...state.newUserStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      newUserData: action.payload.data,
    }),
    addNewUserFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      newUserStates: {
        ...state.newUserStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const { addNewUser, addNewUserFailure, addNewUserSuccess } =
  newUsersSlice.actions;
export const newUsersReducer = newUsersSlice.reducer;
