import { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchRolesDataSuccess,
  fetchRolesFailure,
} from "../slices/features/roles";
import { RequestTypes } from "../types";

const rolesService = new AppService();

function* getRoles() {
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      rolesService.getCompanyRoles,
      baseUrl
    );

    yield put(fetchRolesDataSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchRolesFailure({ statusCode, statusText }));
  }
}

export function* watchGetRoles() {
  yield takeLatest(RequestTypes.FETCH_ROLES, getRoles);
}
