import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const companySelector = (state: TReduxState) => state.features.company;

export const getBaseUrl = createSelector(companySelector, (app) => app.baseUrl);

export const getCompanyData = createSelector(
  companySelector,
  (app) => app.companyData
);

export const getCompanyStates = createSelector(
  companySelector,
  (app) => app.companyStates
);

export const getCompanyLoadingState = createSelector(
  getCompanyStates,
  (companyStates) => companyStates.isLoading
);
