import { DownloadOutlined } from "@ant-design/icons";
import { DatePicker, FloatButton, Form, Select, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/button";
import ReportsTable from "src/components/reports-table";
import ENDPOINTS, { Limit, baseUrl } from "src/constants/endpoints";
import { sortingBy } from "src/constants/samples";
import { getSearchTerm } from "src/store/selectors/features/app";
import { getinnerReportLoadingState } from "src/store/selectors/features/reportById";
import {
  getreportsData,
  getreportsLoadingState,
} from "src/store/selectors/features/reports";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import { capitalizeFirstLetter } from "src/utils/helper-functions";

const { Option } = Select;
const { RangePicker } = DatePicker;

const reportsColumns: any = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: 100,
  },
  {
    title: "DID Status",
    dataIndex: "status",
    key: "didstatus",
    fixed: "left",
  },
  {
    title: "DID",
    dataIndex: "did",
    key: "did",
    fixed: "left",
  },
  {
    title: "User Name",
    dataIndex: "username",
    key: "username",
    fixed: "left",
  },
  {
    title: "Company Name",
    dataIndex: "company",
    key: "companyname",
  },
  {
    title: "Outbound Units",
    dataIndex: "outBound",
    key: "outbound",
  },
  {
    title: "Inbound Units",
    dataIndex: "inBound",
    key: "inbound",
  },
];

const data: any[] = [];

for (let i = 0; i < 600; i++) {
  data.push({
    id: i + 1,
    didstatus: i % 3 !== 0 ? "Active" : "Inactive",
    did: Math.round(Math.random() * 1000000),
    username: `user_name${i}`,
    companyname: `Organic${i}`,
    outbound: `${Math.round(Math.random() * 100)}/2500`,
    inbound: `${Math.round(Math.random() * 100)}/2500`,
  });
}

export const Reports: React.FC = () => {
  const dispatch = useDispatch();
  const getReportState = useSelector(getreportsData);
  const searchTerm = useSelector(getSearchTerm);
  const [myRange, setMyRange] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [myData, setMyData] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isLoadingReports = useSelector(getinnerReportLoadingState);
  const isReportsLoading = useSelector(getreportsLoadingState);

  useEffect(() => {
    setMyData(getReportState);
  }, [getReportState]);

  function buildApiUrl({
    page,
    sortType,
    search,
    downloadAs,
    startDate,
    endDate,
  }: any) {
    const limit = Limit;

    const queryParams = new URLSearchParams();

    page && queryParams.append("page", page);
    sortType && queryParams.append("sortBy", sortType);
    downloadAs && queryParams.append("downloadAs", downloadAs);
    startDate && queryParams.append("startDate", startDate);
    endDate && queryParams.append("endDate", endDate);
    search && queryParams.append("search", search);
    queryParams.append("limit", limit);

    return `?${queryParams.toString()}`;
  }

  function convertDatesToUtcAndEst(filterFn: string) {
    const sortType = filterFn.toString().toUpperCase();

    const query = {
      page: currentPage,
      sortType: sortType ? sortType : undefined,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
    };

    dispatch(
      RequestAppAction.fetchReports({
        data: buildApiUrl(query),
        cbSuccess: () => {
          setIsLoading(false);
        },
      })
    );

    setSortBy(sortType);
  }

  useEffect(() => {
    const query = {
      page: currentPage,
      search: "",
    };

    dispatch(
      RequestAppAction.fetchReports({
        data: buildApiUrl({ ...query }),
        cbSuccess: () => {
          setIsLoading(false);
        },
      })
    );

    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  const changePage = (page: any) => {
    const query = {
      page: page,
      sortType: sortBy,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
    };

    dispatch(
      RequestAppAction.fetchReports({
        data: buildApiUrl(query),
        cbSuccess: () => {
          setIsLoading(false);
        },
      })
    );

    setCurrentPage(page);
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      const query = {
        page: currentPage,
        sortType: sortBy,
        search: searchTerm,
        startDate: myRange.length > 1 ? myRange[0] : null,
        endDate: myRange.length > 1 ? myRange[1] : null,
      };

      dispatch(
        RequestAppAction.fetchReports({
          data: buildApiUrl(query),
          cbSuccess: () => {
            setIsLoading(false);
          },
        })
      );
    }, 800);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [searchTerm]);

  const GenerateRangeData = () => {
    if (myRange.length > 1) {
      const query = {
        sortType: sortBy,
        search: searchTerm,
        startDate: myRange.length > 1 ? myRange[0] : null,
        endDate: myRange.length > 1 ? myRange[1] : null,
      };

      dispatch(
        RequestAppAction.fetchReports({
          data: buildApiUrl(query),
          cbSuccess: () => {
            setIsLoading(false);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (myRange.length > 1) {
      if (myRange[0] === "" && myRange[1] === "") {
        setMyRange([]);
        setCurrentPage(1);

        const query = {
          page: 1,
          sortType: sortBy,
          search: searchTerm,
        };

        dispatch(
          RequestAppAction.fetchReports({
            data: buildApiUrl(query),
            cbSuccess: () => {
              setIsLoading(false);
            },
          })
        );
      }
    }
  }, [myRange]);

  const download = (type: string) => {
    const query = {
      sortType: sortBy,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
      downloadAs: type,
    };

    const url = baseUrl + ENDPOINTS.REPORTS + buildApiUrl(query);

    return url;
  };

  return (
    <div style={{ padding: "1rem 3rem", height: "100%", position: "relative" }}>
      <Spin
        style={{
          height: "100%",
          width: "100%",
          background: "transparent",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isLoading || isReportsLoading || isLoadingReports ? 1 : 0,
        }}
        spinning={isLoading || isReportsLoading || isLoadingReports}
      />
      <div
        style={{
          opacity: isLoading ? 0 : 1,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form>
            <Form.Item name="sortBy" label="Sort By:">
              <div style={{ width: "9.375rem" }}>
                <Select
                  onChange={(e) => {
                    convertDatesToUtcAndEst(e);
                  }}
                  placeholder="Outbound SMS"
                >
                  {sortingBy.map((sort, index) => (
                    <Option key={index} value={sort}>
                      {capitalizeFirstLetter(
                        sort?.toString()?.toLocaleLowerCase()
                      )}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Form>
          <Space
            align="center"
            style={{
              margin: 0,
              padding: 0,
              alignItems: "center",
              display: "flex",
              gap: 20,
              marginTop: "-1rem",
              position: "relative",
            }}
          >
            <RangePicker onChange={(_, b) => setMyRange(b)} />
            <Button
              onBtnClick={() => {
                GenerateRangeData();
              }}
              title="Generate"
              icon
            />
          </Space>
          <Space
            align="center"
            style={{
              margin: 0,
              padding: 0,
              alignItems: "center",
              display: "flex",
              gap: 20,
              position: "relative",
            }}
          >
            <a href={download("CSV")}>
              <div>
                <FloatButton
                  shape="circle"
                  style={{ top: -30, right: 0, position: "absolute" }}
                  icon={<DownloadOutlined />}
                />
              </div>
            </a>
          </Space>
        </Space>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            overflowY: "auto",
            flex: "1 1 auto",
          }}
        >
          <ReportsTable
            spin={isLoading}
            columns={reportsColumns}
            data={myData?.items ? myData?.items : []}
            onChangePage={(page: any) => {
              changePage(page);
            }}
            totalPages={getReportState?.meta?.totalCount}
          />
        </div>
      </div>
    </div>
  );
};
