import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchUserSettingsFailure,
  fetchUserSettingsSuccess,
} from "../slices/features/userSettings";
import { RequestTypes } from "../types";

const appService = new AppService();

function* fetchUseSettings(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.fetchUserSettings,
      baseUrl
    );

    yield put(fetchUserSettingsSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    payload?.cbFailure && payload?.cbFailure();

    yield put(fetchUserSettingsFailure({ statusCode, statusText }));
  }
}

export function* watchUserSettings() {
  yield takeLatest(RequestTypes.FETCH_USER_REQUEST, fetchUseSettings);
}
