import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ROLES } from "src/constants/roles";
import { AuthService } from "src/services/auth";
import { LocalStorageService } from "src/services/local-storage";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchLoginDataFailure,
  fetchLoginDataSuccess,
} from "../slices/features/auth";
import { RequestTypes } from "../types";

const authService = new AuthService();
const localStorageService = new LocalStorageService();

function* fetchLogin(action: any) {
  const { payload } = action;
  const { Email, password, t } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const data = {
      username: Email,
      password: password,
    };

    const response: AxiosResponse<any> = yield call(
      authService.signIn,
      baseUrl,
      data
    );

    const role = response?.data?.role?.toLowerCase();

    if (
      role === ROLES.SUPERADMINFULL.toLowerCase() ||
      role === ROLES.SUPERADMINLIMITED.toLowerCase() ||
      role === ROLES.SUPERADMINUNITE.toLowerCase() ||
      role === ROLES.ADMIN.toLowerCase()
    ) {
      yield call(
        localStorageService.persist,
        "user",
        JSON.stringify(response?.data)
      );
      yield call(
        localStorageService.persist,
        "userData",
        JSON.stringify(response)
      );
      yield call(
        localStorageService.persist,
        "authToken",
        response?.data?.jwtToken
      );
      yield put(fetchLoginDataSuccess({ ...response }));
    } else {
      payload?.notAuthorized && payload?.notAuthorized();
      toast.error(t("notification.notAuthorized"));
    }
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchLoginDataFailure({ statusCode, statusText, message }));
  }
}

export function* watchLogin() {
  yield takeLatest(RequestTypes.LOGIN_REQUEST, fetchLogin);
}
