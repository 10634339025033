import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  companyStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  companyData: null,
};

export const updateCompanySlice = createSlice({
  name: "updateCompany",
  initialState: INITIAL_STATE,
  reducers: {
    initiateUpdateCompany: (state, { payload }) => {
      state.companyStates.isLoading = true;
      payload;
    },
    updateCompanySuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      companyData: action.payload.data,
    }),
    updateCompanyFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      companyStates: {
        ...state.companyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  initiateUpdateCompany,
  updateCompanySuccess,
  updateCompanyFailure,
} = updateCompanySlice.actions;
export const updateCompanyReducer = updateCompanySlice.reducer;
