import { LockFilled, MailFilled } from "@ant-design/icons";
import { Checkbox, Form, Input, Space, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/components/button";
import Header from "src/components/header";
import { LocalStorageService } from "src/services/local-storage";
import { getLoginLoadingState } from "src/store/selectors/features/login";
import RequestAuthAction from "src/store/slices/actions";
import {
  fetchLoginDataSuccess,
  setLoadingFalse,
} from "src/store/slices/features/auth";
import styles from "./login.module.scss";

const localStorageService = new LocalStorageService();

const { Text } = Typography;

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const loginLoading = useSelector(getLoginLoadingState);
  const [remember, setRemember] = useState(false);
  const { t } = useTranslation();

  const [userValues, setUserValues] = useState<{
    username: string;
    password: string;
  } | null>(null);

  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  const [form] = useForm();

  const checkLoggedIn = async () => {
    const token = await localStorageService.fetch("authToken");
    const userData = await localStorageService.fetch("userData");

    if (token && userData) {
      dispatch(fetchLoginDataSuccess(JSON.parse(userData)));
    }
  };

  useLayoutEffect(() => {
    checkLoggedIn();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  const onFinish = (values: any) => {
    const { Email, password } = values;

    form
      .validateFields()
      .then(() => {
        if (remember) {
          localStorageService.persist("rememberUser", JSON.stringify(values));
        } else {
          localStorageService.remove("rememberUser");
        }

        dispatch(
          RequestAuthAction.callLogin({
            Email,
            password,
            t,
            notAuthorized: () => {
              setLoading(false);
              dispatch(setLoadingFalse());
            },
          })
        );
      })
      .catch(() => {
        form.resetFields();
      });
    setLoading(false);
  };

  useEffect(() => {
    localStorageService.fetch("rememberUser").then((res: any) => {
      if (res) {
        const parsedData = JSON.parse(res);

        setUserValues({
          username: parsedData.Email,
          password: parsedData.password,
        });
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      Email: userValues?.username,
      password: userValues?.password,
    });
  }, [userValues]);

  return (
    <div className={styles.login_form_container}>
      <Space align="center" className={styles.login_header}>
        <Header />
      </Space>
      <Space direction="vertical" className={styles.login_form_wrapper}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
          className={`login-form ${styles.login_form}`}
        >
          <Spin spinning={loginLoading}>
            <Form.Item
              name="Email"
              label="Username"
              rules={[
                { required: true, message: "Please, input your Username!" },
              ]}
            >
              <Input
                prefix={<MailFilled className={styles.site_form_item_icon} />}
                placeholder="Username"
                className={styles.login_inputs}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please, input your Password!" },
              ]}
            >
              <Input
                prefix={<LockFilled className={styles.site_form_item_icon} />}
                type="password"
                placeholder="Password"
                className={styles.login_inputs}
              />
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox
                  value={remember}
                  onChange={() => {
                    setRemember(!remember);
                  }}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
            </Form.Item>

            <div style={{ marginTop: "-1rem", marginBottom: "1rem" }}>
              <Text
                onClick={() => {
                  navigate("/forgot-password");
                }}
                className={styles.underline_text}
              >
                {t("underlineText.forgetPassword")}
              </Text>
            </div>

            <Form.Item>
              <Button
                title="LOGIN"
                buttonType="submit"
                btnClass="full_btn"
                loading={loading}
              />
            </Form.Item>
          </Spin>
        </Form>
      </Space>
    </div>
  );
};

export default Login;
