import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  rolesStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  rolesData: null,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState: INITIAL_STATE,
  reducers: {
    initiateFetchRoles: (state) => {
      state.rolesStates.isLoading = true;
    },
    fetchRolesDataSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      rolesStates: {
        ...state.rolesStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      rolesData: action.payload.data,
    }),
    fetchRolesFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      rolesStates: {
        ...state.rolesStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearRoles: (state) => ({
      ...state,
      rolesStates: {
        ...state.rolesStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      rolesData: null,
    }),
  },
});

export const {
  initiateFetchRoles,
  fetchRolesDataSuccess,
  fetchRolesFailure,
  clearRoles,
} = rolesSlice.actions;
export const rolesReducer = rolesSlice.reducer;
