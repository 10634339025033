import { PlusCircleOutlined } from "@ant-design/icons";
import { Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCreateCompanyStates } from "src/store/selectors/features/createCompany";
import { getRolesData } from "src/store/selectors/features/roles";
import { initiateCreateCompany } from "src/store/slices/features/createCompany";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import Button from "../button";
import UserDetails from "../user-details";
import CreateAccountForm from "./create-account-form";
import styles from "./createAccount.module.scss";

const CreateAccount = () => {
  const [numberOfUserForms, setNumberOfUserForms] = useState<number>(1);
  const [formData, setFormData] = useState<any>(null);
  const [isFieldRequired, setIsFieldRequired] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const createCompantState = useSelector(getCreateCompanyStates);
  const navigate = useNavigate();
  const roles: any = useSelector(getRolesData);

  const handleFieldChange = (e: any) => {
    const fieldValue = e.target?.value;
    setIsFieldRequired(fieldValue.length > 0);

    // If you need to perform additional actions on field change, you can do it here.
  };

  useEffect(() => {
    dispatch(initiateFetchRoles());
  }, []);

  const userFormsArray = Array.from(
    { length: numberOfUserForms },
    (_, index) => index
  );

  const handleSave = () => {
    form.submit();
  };

  const handleUserDetailsForm = (userData: any, userIndex: any) => {
    const updatedFormData = { ...formData };
    updatedFormData[`userDetails${userIndex}`] = userData;
    setFormData(updatedFormData);
  };

  const rolesName = (val: any) => {
    const currentRole = roles?.filter((role: any) => role?.name == val);

    if (currentRole) {
      return currentRole[0]?.id;
    }
  };

  const onFinish = (values: any) => {
    const {
      companyName,
      timezone,
      companyStatus,
      category,
      notes,
      plan,
      billedExternally,
    } = values;

    if (companyName && timezone && companyStatus && category) {
      const numUsers = Object.keys(values).filter((key) =>
        key.startsWith("userName_")
      ).length;

      const payloadData: any = {
        name: companyName,
        timeZone: timezone,
        status: companyStatus?.toString()?.toLocaleUpperCase(),
        category: category?.toString()?.toLocaleUpperCase(),
        notes,
        users: [],
        stripeProductId: plan,
        billedExternally: billedExternally,
      };

      if (isFieldRequired) {
        for (let i = 0; i < numUsers; i++) {
          const permissionVal = values[`permissionLevel_${i}`];

          payloadData.users.push({
            username: values[`userName_${i}`],
            name: values[`contactName_${i}`],
            roleId:
              permissionVal === roles[0]?.name
                ? rolesName(permissionVal)
                : values[`permissionLevel_${i}`],
            status: values[`userStatus_${i}`].toString().toLocaleUpperCase(),
            email: values[`mainEmail_${i}`],
            additionalEmail: values[`additionalEmail_${i}`],
            dids: [],
          });

          const didKeys = Object.keys(values).filter((key) =>
            new RegExp(`did_user${i}_\\d+$`).test(key)
          );

          const numDids = didKeys.length;

          for (let j = 0; j < numDids; j++) {
            payloadData.users[i].dids.push({
              did: values[`did_user${i}_${j}`],
              status: values[`didStatus_user${i}_${j}`]
                ?.toString()
                ?.toLocaleUpperCase(),
              email: values[`notificationEmail_user${i}_${j}`],
            });
          }
        }
      }

      if (!createCompantState?.isLoading) {
        dispatch(initiateCreateCompany(payloadData));
      }

      if (!createCompantState.error) {
        form.resetFields();
        navigate("/");
      }
    }
  };

  const userForms = userFormsArray.map((item, i) => (
    <UserDetails
      key={i}
      setNumberOfUserForms={setNumberOfUserForms}
      items={item}
      count={i}
      form={form}
      isFieldRequired={isFieldRequired}
      handleFieldChange={handleFieldChange}
      onUserDetailsForm={(userData: any) => handleUserDetailsForm(userData, i)}
    />
  ));

  return (
    <Space
      style={{
        height: "100%",
        display: "flex",
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
      className={styles.create_account_wrapper}
      direction="vertical"
      size={"large"}
    >
      <Form
        style={{
          height: "100%",
          display: "flex",
          width: "100%",
          position: "relative",
          flexDirection: "column",
        }}
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
      >
        <Space
          style={{
            display: "flex",
            padding: "1rem",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
            paddingBottom: "0.5rem",
          }}
        >
          <Button title="Save" onBtnClick={handleSave} buttonType="submit" />
          <Button
            title="Cancel"
            onBtnClick={() => navigate(-1)}
            btnClass="outlined_btn"
          />
        </Space>
        <div
          style={{
            padding: "1rem",
            overflowY: "auto",
            display: "flex",
            height: window.innerHeight - 210,
            paddingBottom: "3rem",
            flexDirection: "column",
          }}
        >
          <CreateAccountForm form={form} />
          {userForms}
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1rem",
              gap: 10,
            }}
          >
            <Button
              title="Add New User"
              icon={<PlusCircleOutlined />}
              onBtnClick={() => {
                if (numberOfUserForms < 5) {
                  setNumberOfUserForms(numberOfUserForms + 1);
                }
              }}
            />
          </Space>
        </div>
      </Form>
    </Space>
  );
};

export default CreateAccount;
