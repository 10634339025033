import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchPlansFailure, fetchPlansSuccess } from "../slices/features/plan";
import { RequestTypes } from "../types";

const reportsService = new AppService();

function* getPlan(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      reportsService.getPlanRequest,
      baseUrl
    );

    yield put(fetchPlansSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchPlansFailure({ statusCode, statusText }));
  }
}

export function* watchGetPlanRequest() {
  yield takeLatest(RequestTypes.GET_PLAN_REQUEST, getPlan);
}
