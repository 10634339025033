import React from "react";
import CreateNewUser from "src/components/add-new-user";

export const AddNewUser: React.FC = () => {
  return (
    <div>
     <CreateNewUser/>
    </div>
  );
};
