import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  historyStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  historyData: null,
};

export const HistorySlice = createSlice({
  name: "history",
  initialState: INITIAL_STATE,
  reducers: {
    fetchHistory: (state, { payload }) => {
      state.historyStates.isLoading = true;
      payload;
    },
    fetchHistorySuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      historyStates: {
        ...state.historyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      historyData: action.payload.data,
    }),
    fetchHistoryFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      historyStates: {
        ...state.historyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearHistory: (state) => ({
      ...state,
      historyStates: {
        ...state.historyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      historyData: null,
    }),
  },
});

export const {
  fetchHistory,
  fetchHistorySuccess,
  fetchHistoryFailure,
  clearHistory,
} = HistorySlice.actions;
export const historyReducer = HistorySlice.reducer;
