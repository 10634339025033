import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/did";
import {
  fetchVerifyTokenFailure,
  fetchVerifyTokenSuccess,
} from "../slices/features/verifyToken";
import { RequestTypes } from "../types";

const appService = new AppService();

function* verifyToken(action: any) {
  const { payload } = action;
  const { userId, token } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const data = {
      token: token,
    };

    const response: AxiosResponse<any> = yield call(
      appService.postVerifyToken,
      baseUrl,
      userId,
      data
    );

    payload?.cbSuccess && payload?.cbSuccess();

    yield put(fetchVerifyTokenSuccess({ ...response }));
  } catch (errors: any) {
    const { statusCode, statusText } = errors;
    const message = errors?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchVerifyTokenFailure({ statusCode, statusText }));
  }
}

export function* watchVerifyToken() {
  yield takeLatest(RequestTypes.POST_VERIFY_TOKEN, verifyToken);
}
