import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "src/constants/response-types";
import { CompanyStatus } from "src/constants/samples";
import {
  getRolesData,
  getRolesLoading,
} from "src/store/selectors/features/roles";
import RequestAppAction from "src/store/slices/appActions";
import Colors from "src/themes/colors";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import Button from "../button";
import styles from "./userDetails.module.scss";

const { Text, Title } = Typography;
const { Option } = Select;

const UserDetails = ({
  setNumberOfUserForms,
  count,
  handleFieldChange,
  isFieldRequired,
  form,
}: {
  setNumberOfUserForms: any;
  items?: any;
  count: number;
  onUserDetailsForm: any;
  form: any;
  handleFieldChange?: any;
  isFieldRequired: boolean;
}) => {
  const roles: any = useSelector(getRolesData);
  const Loading: any = useSelector(getRolesLoading);
  const [isLoading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const [didRow, setDidRow] = useState<number>(1);

  const [userInput, setUserInput] = useState<{
    value: string | null;
    username: string | null;
  }>({ value: null, username: null });

  const didRowsArray = Array.from({ length: didRow }, (_, index) => index);

  useEffect(() => {
    setIsloading(Loading);
  }, [Loading]);

  const validateInput = (_: any, value: string, callback: any) => {
    const regexPattern = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

    if (regexPattern.test(value)) {
      callback();
    } else {
      callback("Did is not Valid");
    }
  };

  const validateUserName = (_: any, value: string, callback: any) => {
    const regexUsername =
      /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$/;

    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regexUsername.test(value) || regexEmail.test(value)) {
      callback();
    } else {
      callback("Username is not valid");
    }
  };

  const validate = (_: any, __: any, callback: any) => {
    callback();
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch(
        RequestAppAction.verifyUser({
          username: userInput.value,
          cbSuccess: (res: any) => {
            if (res?.statusCode === STATUS_CODES.SUCCESS) {
              form.setFields([
                {
                  name: userInput?.username,
                  errors: "",
                },
              ]);
            }
          },
          cbFailure: (e: string) => {
            form.setFields([
              {
                name: userInput?.username,
                errors: [`${e}`],
              },
            ]);
          },
        })
      );
    }, 800);

    return () => {
      clearTimeout(debounce);
    };
  }, [userInput]);

  return (
    <>
      {isLoading ? (
        <Space className={styles.loading_space} align="center">
          <Spin style={{ alignSelf: "center" }} />
        </Space>
      ) : (
        <div className={styles.user_details_wrapper}>
          <Space
            align="center"
            className={styles.user_details_header}
            style={{
              backgroundColor: Colors.SecondaryLight,
            }}
          >
            <Title level={5} style={{ margin: 0 }}>
              User {count + 1} Details
            </Title>
            <Space size="middle" align="center">
              <Button
                onBtnClick={() => {
                  setNumberOfUserForms((pre: any) => pre - 1);
                }}
                shape="circle"
                title=""
                icon={<DeleteOutlined />}
              ></Button>
            </Space>
          </Space>

          <div className={styles.form_wrapper}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name={`userName_${count}`}
                  label={
                    <span>
                      {!isFieldRequired && (
                        <Text className={styles.form_required_field}>*</Text>
                      )}
                      <Text>User Name</Text>
                    </span>
                  }
                  rules={[
                    { required: isFieldRequired, message: "User Name" },
                    {
                      validator: isFieldRequired ? validateUserName : validate,
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => (
                      handleFieldChange(e),
                      setUserInput({
                        value: e.target.value,
                        username: `userName_${count}`,
                      })
                    )}
                    placeholder="User Name"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={`contactName_${count}`}
                  label={
                    <span>
                      {!isFieldRequired && (
                        <Text className={styles.form_required_field}>*</Text>
                      )}
                      <Text>Contact Name</Text>
                    </span>
                  }
                  rules={[
                    {
                      required: isFieldRequired,
                      message: "Please Provide contact name",
                    },
                  ]}
                >
                  <Input
                    onChange={handleFieldChange}
                    placeholder="Contact Name"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={`permissionLevel_${count}`}
                  initialValue={roles[0]?.name}
                  label={
                    <span>
                      {!isFieldRequired && (
                        <Text className={styles.form_required_field}>*</Text>
                      )}
                      <Text>Permission Level</Text>
                    </span>
                  }
                  rules={[
                    {
                      required: isFieldRequired,
                      message: "Permission level is required",
                    },
                  ]}
                >
                  <Select>
                    {roles?.map((role: any) => (
                      <Option key={role.id} value={role.id}>
                        {capitalizeFirstLetter(role.name)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name={`userStatus_${count}`}
                  label={
                    <span>
                      {!isFieldRequired && (
                        <Text className={styles.form_required_field}>*</Text>
                      )}
                      <Text>User Status</Text>
                    </span>
                  }
                  initialValue={capitalizeFirstLetter(
                    CompanyStatus.Active.toString().toLocaleLowerCase()
                  )}
                  rules={[
                    {
                      required: isFieldRequired,
                      message: "Please select user status",
                    },
                  ]}
                >
                  <Select placeholder="Select User Status">
                    {Object.values(CompanyStatus).map((timezone, index) => (
                      <Option key={index} value={timezone}>
                        {capitalizeFirstLetter(
                          timezone.toString().toLocaleLowerCase()
                        )}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name={`mainEmail_${count}`}
                  label={
                    <span>
                      {!isFieldRequired && (
                        <Text className={styles.form_required_field}>*</Text>
                      )}
                      <Text>Main Email</Text>
                    </span>
                  }
                  rules={[
                    {
                      required: isFieldRequired,
                      message: "Please enter email address",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input onChange={handleFieldChange} placeholder="Email" />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  name={`additionalEmail_${count}`}
                  label="Additional Email"
                >
                  <Input placeholder="Additional Email" />
                </Form.Item>
              </Col>

              {didRowsArray.map((_, didIndex) => (
                <>
                  <Col span={6}>
                    <Form.Item
                      name={`did_user${count}_${didIndex}`}
                      label={
                        <span>
                          {!isFieldRequired && (
                            <Text className={styles.form_required_field}>
                              *
                            </Text>
                          )}
                          <Text>DID</Text>
                        </span>
                      }
                      rules={[
                        {
                          required: isFieldRequired,
                          message: "Please enter DIDs",
                        },
                        {
                          validator: isFieldRequired ? validateInput : validate,
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => handleFieldChange(e)}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <Form.Item
                      name={`didStatus_user${count}_${didIndex}`}
                      label={
                        <span>
                          {!isFieldRequired && (
                            <Text className={styles.form_required_field}>
                              *
                            </Text>
                          )}
                          <Text>DID Status</Text>
                        </span>
                      }
                      initialValue={capitalizeFirstLetter(
                        CompanyStatus.Pending.toString().toLocaleLowerCase()
                      )}
                      rules={[
                        {
                          required: isFieldRequired,
                          message: "please select DID status",
                        },
                      ]}
                    >
                      <Select placeholder="Select DID Status">
                        {Object.values(CompanyStatus).map((timezone, index) => (
                          <Option key={index} value={timezone}>
                            {capitalizeFirstLetter(
                              timezone.toString().toLocaleLowerCase()
                            )}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    span={1}
                    style={{
                      color: Colors.Secondary,
                      fontSize: "1.2rem",
                      display: "flex",
                    }}
                  >
                    {didRow > 1 ? (
                      <>
                        <PlusCircleOutlined
                          style={{ cursor: "pointer", marginRight: "1rem" }}
                          onClick={() => {
                            setDidRow((prev) => prev + 1);
                          }}
                        />
                        <MinusCircleOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (didRow > 1) {
                              setDidRow((prev) => prev - 1);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <PlusCircleOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDidRow((prev) => prev + 1);
                        }}
                      />
                    )}
                  </Col>
                </>
              ))}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
