import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { deleteDidFailure, deleteDidSuccess } from "../slices/features/did";
import { RequestTypes } from "../types";

const didsService = new AppService();

function* DeleteDid(action: any) {
  const { payload } = action;
  const { id, userId } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      didsService.deleteDid,
      baseUrl,
      userId,
      id
    );

    yield put(deleteDidSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(deleteDidFailure({ statusCode, statusText }));
  }
}

export function* watchDeleteDid() {
  yield takeLatest(RequestTypes.DELETE_DID_REQUEST, DeleteDid);
}
