import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./Auth";

export const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<Auth />}></Route>
    </Routes>
  </BrowserRouter>
);
