import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const usersSelector = (state: TReduxState) => state.features.users;

export const getBaseUrl = createSelector(usersSelector, (app) => app.baseUrl);

export const getUsersData = createSelector(
  usersSelector,
  (app) => app.usersData
);

export const getUsersStates = createSelector(
  usersSelector,
  (app) => app.usersStates
);

export const getUsersLoadingState = createSelector(
  getUsersStates,
  (usersStates) => usersStates.isLoading
);
