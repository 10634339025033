import { ArrowLeftOutlined } from "@ant-design/icons";
import { Layout, Space, Typography, theme } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getSearchTerm } from "src/store/selectors/features/app";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import AppBreadcrumbs from "../breadcrumbs";
import SearchBar from "../search-bar";
import styles from "./dashboardHeader.module.scss";

const { Header } = Layout;

const { Title } = Typography;

const DashboardHeader = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const searchTerm = useSelector(getSearchTerm);

  useEffect(() => {
    if (searchTerm.length < 1) {
      setSearchText(searchTerm);
    }
  }, [searchTerm]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useLayoutEffect(() => {
    dispatch(RequestAppAction.fetchUserDetails());
  }, []);

  return (
    <Header
      className={styles.dashboard_header_wrapper}
      style={{
        background: colorBgContainer,
      }}
    >
      <AppBreadcrumbs />
      <Space className={styles.header_title_wrapper}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {pathSnippets.length > 1 ? (
            <ArrowLeftOutlined
              style={{
                marginTop: "0.5rem",
                marginLeft: "-2rem",
                marginRight: "1rem",
                zIndex: 1,
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : null}
          <Title
            level={3}
            style={{ marginBottom: pathSnippets.length > 1 ? "0rem" : 0 }}
            className={styles.header_title}
          >
            {title?.replace(/%20/g, " ")}
          </Title>
        </div>
        {location.pathname === "/" ||
        location.pathname === "/reports" ||
        (pathSnippets.length > 0 && pathSnippets[0] === "reports") ||
        location.pathname === "/accounts" ||
        location.pathname === "/index.html" ? (
          <SearchBar
            placeholder={
              location.pathname === "/accounts" ||
              location.pathname === "/index.html" ||
              location.pathname === "/"
                ? "Search by Company Name"
                : "Search by Report"
            }
            onSearch={(text) => {
              dispatch(setSearchTerm(text));
            }}
            setSearchValue={(e) => (
              setSearchText(e), dispatch(setSearchTerm(e))
            )}
            searchValue={searchText}
          />
        ) : (
          <></>
        )}
      </Space>
    </Header>
  );
};

export default DashboardHeader;
