import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const userDetailsSelector = (state: any) => state.features.userDetails;

export const getBaseUrl = createSelector(
  userDetailsSelector,
  (app) => app.baseUrl
);

export const getuserDetailsData = createSelector(
  userDetailsSelector,
  (app) => app.userDetails
);

export const getUserDetailsStates = createSelector(
  userDetailsSelector,
  (app) => app.userDetailsStates
);
export const getDetailsStates = createSelector(
  userDetailsSelector,
  (app) => app.userDetailsStates
);

export const getUserDetailsLoadingState = createSelector(
  getUserDetailsStates,
  (userDetailsStates) => userDetailsStates.isLoading
);

export const getDetailsLoadingState = createSelector(
  getDetailsStates,
  (userDetailsStates) => userDetailsStates.isLoading
);
