import { PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Modal, Space, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Limit } from "src/constants/endpoints";
import { CompanyStatus, DateFormat } from "src/constants/samples";
import { DataType } from "src/constants/types";
import { getSearchTerm } from "src/store/selectors/features/app";
import {
  getCompanyData,
  getCompanyLoadingState,
} from "src/store/selectors/features/company";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import { fetchCompaniesData } from "src/store/slices/features/company";
import Colors from "src/themes/colors";
import Button from "../../components/button";
import CustomTable from "../../components/custom-table";
import Switch from "../../components/switch";

export const columns: ColumnsType<DataType> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: 70,
  },
  {
    title: "Company Name",
    dataIndex: "companyname",
    key: "companyname",
    fixed: "left",
    sorter: (a: any, b: any) => a.companyname.length - b.companyname.length,
    sortDirections: ["ascend", "descend", "ascend"],
    render: (text, record) => ({
      props: {
        style: {
          color:
            record.status === CompanyStatus.Active
              ? Colors.Secondary
              : "inherit",
        },
      },
      children: <span>{text}</span>,
    }),
    width: 180,
  },
  {
    title: "Monthly Usage",
    dataIndex: "monthlyusage",
    key: "monthlyusage",
    sorter: (a: any, b: any) => a.monthlyusage.length - b.monthlyusage.length,
    sortDirections: ["ascend", "descend", "ascend"],
    width: 150,
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    sorter: (a: any, b: any) => a.category.length - b.category.length,
    sortDirections: ["ascend", "descend", "ascend"],
    width: 120,
    render: (text) => ({
      children: <span>{text || "-"}</span>,
    }),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 100,
  },
  {
    title: "Created At",
    dataIndex: "created",
    key: "created",
    sorter: (a: any, b: any) => a.created.length - b.created.length,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Last Activity",
    dataIndex: "lastactivity",
    key: "lastactivity",
    sorter: (a: any, b: any) => a.lastactivity.length - b.lastactivity.length,
    sortDirections: ["ascend", "descend", "ascend"],
  },
];

interface dataParams {
  page?: number;
  limit: string;
  search?: string;
  status?: string;
  cbSuccess?: () => void;
}

export const Accounts: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const companyData: any = useSelector(getCompanyData);
  const searchTerm = useSelector(getSearchTerm);
  const [isLoading, setIsLoading] = useState(true);
  const [showActive, setShowActive] = useState(false);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const loading: any = useSelector(getCompanyLoadingState);
  const { t } = useTranslation();
  const [rowSelected, setRowSelected] = useState<boolean>(false);

  useEffect(() => {
    if (!showActive) {
      const data: dataParams = {
        page: 1,
        limit: Limit,
        search: encodeURIComponent(searchTerm),
        status: CompanyStatus.Active,
        cbSuccess: () => {
          setIsLoading(false);
        },
      };

      dispatch(fetchCompaniesData(data));
    } else {
      const data: dataParams = {
        page: 1,
        limit: Limit,
        search: encodeURIComponent(searchTerm),
        cbSuccess: () => {
          setIsLoading(false);
        },
      };

      dispatch(fetchCompaniesData(data));
    }
  }, [showActive]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      const data: dataParams = {
        page: 1,
        limit: Limit,
        search: encodeURIComponent(searchTerm),
        cbSuccess: () => {
          setIsLoading(false);
        },
      };

      if (!showActive) {
        data["status"] = CompanyStatus.Active;
      }

      dispatch(fetchCompaniesData(data));
    }, 800);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (companyData && companyData.items && companyData.items.length) {
      const convertedData = companyData.items.map((item: any) => ({
        id: item.id,
        companyname: item.name,
        monthlyusage: `${item?.usageCount || 0}/${item?.usageLimit || 0}`, // You can add logic here to calculate monthly usage.
        category: item.category,
        status: item.status,
        created: format(new Date(item.createdAt), DateFormat),
        lastactivity: format(new Date(item.updatedAt), DateFormat),
      }));

      setTableData(convertedData);
    } else {
      setTableData([]);
    }
  }, [companyData]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        width: "100%",
      }}
      className="layout_padding"
    >
      <Spin
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          zIndex: loading || isLoading ? 1 : 0,
        }}
        spinning={loading || isLoading}
      />
      <div
        style={{
          opacity: isLoading ? 0 : 1,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-1.4rem",
          }}
        >
          <Button
            title="Create New Account"
            icon={<PlusCircleOutlined />}
            onBtnClick={() => navigate("/accounts/create-new-account")}
          />

          <Switch
            onChange={(val) => {
              setShowActive(val);
            }}
            checked={showActive}
            label="Show inactive"
          />
          {rowSelected && (
            <Button
              onBtnClick={() => {
                Modal.confirm({
                  title: t("modal.confirmDelete"),
                  content: t("modal.deleteContent"),
                  onOk() {
                    dispatch(
                      RequestAppAction.bulkDeleteAccounts({
                        data: { companyIds: selectedAccounts },
                        cbSuccess: () => {
                          const data: dataParams = {
                            page: 1,
                            limit: Limit,
                            search: encodeURIComponent(searchTerm),
                            cbSuccess: () => {
                              setIsLoading(false);
                            },
                          };

                          if (!showActive) {
                            data["status"] = CompanyStatus.Active;
                          }

                          setShowActive(false);
                          setRowSelected(false);
                          dispatch(fetchCompaniesData(data));
                        },
                      })
                    );
                  },
                });
              }}
              title="Delete Selected"
              btnClass="outlined_btn"
            />
          )}
        </Space>
        <div
          style={{
            opacity: isLoading ? 0 : 1,
            width: "100%",
            height: "100%",
            display: "flex",
            overflowY: "auto",
          }}
        >
          <CustomTable
            columns={columns}
            data={tableData}
            setSelectedAccounts={setSelectedAccounts}
            setRowSelected={setRowSelected}
            loading={loading}
            showActive={showActive}
            onChangePage={(e: number) => {
              const data: dataParams = {
                page: e,
                limit: Limit,
                search: encodeURIComponent(searchTerm),
              };

              if (!showActive) {
                data["status"] = CompanyStatus.Active;
              }

              dispatch(fetchCompaniesData(data));
            }}
            totalPages={companyData?.meta?.totalCount}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
