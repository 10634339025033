import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  didStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  didData: null,
};

export const didSlice = createSlice({
  name: "did",
  initialState: INITIAL_STATE,
  reducers: {
    deleteDid: (state) => {
      state.didStates.isLoading = true;
    },
    deleteDidSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      didStates: {
        ...state.didStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      didData: action.payload.data,
    }),
    deleteDidFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      didStates: {
        ...state.didStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    updateDid: (state) => {
      state.didStates.isLoading = true;
    },
    updateDidSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      didStates: {
        ...state.didStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      didData: action.payload.data,
    }),
    updateDidFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      didStates: {
        ...state.didStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    addDid: (state) => {
      state.didStates.isLoading = true;
    },
    addDidSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      didStates: {
        ...state.didStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      didData: action.payload.data,
    }),
    addDidFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      didStates: {
        ...state.didStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  deleteDid,
  deleteDidSuccess,
  deleteDidFailure,
  updateDid,
  updateDidSuccess,
  updateDidFailure,
  addDid,
  addDidSuccess,
  addDidFailure
} = didSlice.actions;
export const didReducer = didSlice.reducer;