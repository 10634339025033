import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Col, Form, Input, Row, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PASSWORDREGEX } from "src/constants/endpoints";
import RequestAppAction from "src/store/slices/appActions";
import Colors from "src/themes/colors";
import Button from "../button";
import styles from "./styles.module.scss";

const { Title } = Typography;

interface changePasswordProps {
  changePassword: boolean;
  setChangePassword: (val: boolean) => void;
  setExpand: (val: string) => void;
}

export const ChangePassword = ({
  changePassword,
  setChangePassword,
  setExpand,
}: changePasswordProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const UpdatePassword = (val: any) => {
    if (val.reTypePassword !== val.newPassword) {
      return toast.error(t("errorPassword.title"));
    }

    if (!PASSWORDREGEX.test(val?.newPassword)) {
      return toast.error(t("message.passwordRegexError"));
    }

    if (
      val.reTypePassword === val.newPassword &&
      PASSWORDREGEX.test(val.newPassword)
    ) {
      dispatch(
        RequestAppAction.updatePassword({
          currentPassword: val.currentPassword,
          newPassword: val.newPassword,
        })
      );
      setChangePassword(false);

      // reset password fields
      form.setFieldsValue({
        currentPassword: "",
        newPassword: "",
        reTypePassword: "",
      });
    }
  };

  return (
    <Space
      direction="vertical"
      size={"large"}
      style={{
        borderRadius: "0.625rem",
        width: "100%",
        border: `0.06rem solid ${Colors.Secondary}`,
        margin: "0.5rem 0",
      }}
    >
      <Space
        align="center"
        size={"large"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: Colors.SecondaryLight,
          borderRadius: "0.625rem 0.625rem 0 0",
          padding: "1.3rem 1.5rem",
          width: "100%",
        }}
      >
        <Title level={5} style={{ margin: 0 }}>
          Password & Security
        </Title>
        <Space size="middle" align="center">
          <Button
            shape="circle"
            title=""
            icon={<EditOutlined />}
            onBtnClick={() => {
              window.scrollTo(0, 0);
              setChangePassword(true);
              setExpand("");
            }}
          />
        </Space>
      </Space>

      <Space
        size={"large"}
        direction="vertical"
        className={styles.settings_items_heading}
      >
        <Form form={form} layout={"vertical"} onFinish={UpdatePassword}>
          <Row>
            <Col span={6} style={{ margin: "1rem 0" }}>
              <Form.Item
                name="currentPassword"
                label="Current Password"
                rules={[
                  {
                    message: "Please enter Current password",
                  },
                ]}
              >
                <Input.Password
                  className={styles.pass_input}
                  maxLength={20}
                  placeholder={
                    changePassword ? "Current Password" : "Input Password"
                  }
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              {changePassword ? (
                <>
                  <Col span={24} style={{ margin: "1rem 0" }}>
                    <Form.Item
                      name="newPassword"
                      label="New Password"
                      rules={[
                        {
                          message: "Please enter new password",
                        },
                      ]}
                    >
                      <Input.Password
                        className={styles.pass_input}
                        maxLength={20}
                        placeholder={"New Password"}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ margin: "1rem 0" }}>
                    <Form.Item
                      name="reTypePassword"
                      label="Re-type Password"
                      rules={[
                        {
                          message: "Please re-type new password",
                        },
                      ]}
                    >
                      <Input.Password
                        className={styles.pass_input}
                        maxLength={20}
                        placeholder={"Re-type New Password"}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          {changePassword ? (
            <Space className={styles.pass_change_btn_wrapper} size={"large"}>
              <Button
                onBtnClick={() => setChangePassword(false)}
                title="Cancel"
                btnClass="white_btn"
              />
              <Button title="Change Password" buttonType="submit" />
            </Space>
          ) : (
            <></>
          )}
        </Form>
      </Space>
    </Space>
  );
};
