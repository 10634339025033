import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const userSettingsSelector = (state: any) => state.features.userSettings;

export const getBaseUrl = createSelector(
  userSettingsSelector,
  (app) => app.baseUrl
);

export const getuserSettingsData = createSelector(
  userSettingsSelector,
  (app) => app.userSettings
);

export const getUserSettingsStates = createSelector(
  userSettingsSelector,
  (app) => app.userSettingsStates
);
export const getDetailsStates = createSelector(
  userSettingsSelector,
  (app) => app.userSettingsStates
);

export const getUserSettingsLoadingState = createSelector(
  getUserSettingsStates,
  (userSettingsStates) => userSettingsStates.isLoading
);

export const getDetailsLoadingState = createSelector(
  getDetailsStates,
  (userSettingsStates) => userSettingsStates.isLoading
);
