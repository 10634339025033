import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const createCompanySelector = (state: TReduxState) =>
  state.features.createCompany;

export const getBaseUrl = createSelector(
  createCompanySelector,
  (app) => app.baseUrl
);

export const getCreateCompanyData = createSelector(
  createCompanySelector,
  (app) => app.createCompanyData
);

export const getCreateCompanyStates = createSelector(
  createCompanySelector,
  (app) => app.createCompanyStates
);
