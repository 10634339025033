const Colors = {
  Black: "#000000",
  BlackLightOpacity: "rgba(0, 0, 0, 0.80)",
  White: "#FFFFFF",
  Tertiary: "#1C4DA1",
  transparentBlack: "#00000000",
  InactiveTertiary: "",
  Placeholder: "#B1B1B1",
  Gray: "#757575",
  TitleGray: "#909090",
  Secondary: "#F7941D",
  SecondaryLight: "#f5e6d4",
  darkSecondary: "#D17E1B",
  LightGray: "#D9D9D9",
  mediumGrey: "#eeee",
  GrayLight: "#FAFAFA",
  NeroColor: "#202020",
  AlertBackground: "#1C4DA1",
  DarkGray: "#3E3E3E",
  DarkCharcoal: "#333333",
  SecondaryColorInactive: "#FBCE98",
  // Green: "#00A300",
  LightGreen: "#e5f6dd",
  Green: "#52C41A",
  Red: "#FF4D4F",
  LightRed: "#ffe4e5",
  Blue: "#1c4da1",
  BgGray: "#F0F2F5",
  borderGray: "#d9d9d9",
  lightYellow: "#fefadc",
};

export default Colors;
