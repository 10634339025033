import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { AppService } from "src/services/app";

import toast from "react-hot-toast";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchBulkDeleteAccountsFailure,
  fetchBulkDeleteAccountsSuccess,
} from "../slices/features/company";
import { RequestTypes } from "../types";

const appService = new AppService();

function* bulkDeleteAccountSaga(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.bulkDeleteAccount,
      baseUrl,
      data
    );

    yield put(fetchBulkDeleteAccountsSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchBulkDeleteAccountsFailure({ statusCode, statusText }));
  }
}

export function* watchBulkDeleteAccount() {
  yield takeLatest(RequestTypes.BULK_DELETE_ACCOUNTS, bulkDeleteAccountSaga);
}
