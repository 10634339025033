import { EditOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Space, Spin, Typography } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";

import Colors from "src/themes/colors";
import Button from "../../components/button";

import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "src/components/change-password";
import Switch from "src/components/switch";
import { getRolesData } from "src/store/selectors/features/roles";
import { getuserDetailsData } from "src/store/selectors/features/userDetails";
import {
  getUserSettingsLoadingState,
  getuserSettingsData,
} from "src/store/selectors/features/userSettings";
import RequestAppAction from "src/store/slices/appActions";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import styles from "./settings.module.scss";

const { Title, Text } = Typography;
const { Option } = Select;

const Settings = () => {
  const [expand, setExpand] = useState<string | null>("");
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [currentEmailState, setCurrentEmailState] = useState(false);
  const [currentAudioState, setCurrentAudioState] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userSetitngDetails = useSelector(getuserDetailsData);
  const [form] = Form.useForm();
  const [updatedValues, setUpdatedValues] = useState<any>({});
  const [updating, setUpdating] = useState(true);
  const roles: any = useSelector(getRolesData);
  const mySettings = useSelector(getuserSettingsData);
  const settingsLoading = useSelector(getUserSettingsLoadingState);

  useEffect(() => {
    if (mySettings && updating) {
      if (Array.isArray(mySettings)) {
        setCurrentAudioState(mySettings?.[0]?.audioNotification);

        setCurrentEmailState(mySettings?.[0]?.emailNotification);
      } else {
        setCurrentAudioState(mySettings?.audioNotification);
        setCurrentEmailState(mySettings?.emailNotification);
      }

      // for initial load of the settings toggles
      setUpdating(false);
    }
  }, [mySettings]);

  const getEmailState = (val: boolean) => {
    setCurrentEmailState(val);
    dispatch(
      RequestAppAction.UpdateUserSettings({
        emailNotification: val,
        audioNotification: currentAudioState,
        cbFailure: () => {
          setCurrentEmailState(!val);
        },
      })
    );
  };

  const getAudioState = (val: boolean) => {
    setCurrentAudioState(val);
    dispatch(
      RequestAppAction.UpdateUserSettings({
        emailNotification: currentEmailState,
        audioNotification: val,
        cbFailure: () => {
          setCurrentAudioState(!val);
        },
      })
    );
  };

  const updateUserDetails = (val: any) => {
    const currentRole = roles?.filter(
      (role: any) => val?.permissionLevel == role?.name
    );

    const data: {
      username?: string;
      name?: string;
      additionalEmail?: string;
      email?: string;
      roleId?: number;
    } = {};

    if (updatedValues?.username !== val?.username) {
      data["username"] = val?.username;
    }

    if (updatedValues?.name !== val?.contactName) {
      data["name"] = val?.contactName;
    }

    if (updatedValues?.additionalEmail !== val?.additionalEmail) {
      data["additionalEmail"] = val?.additionalEmail;
    }

    if (updatedValues?.email !== val?.mainEmail) {
      data["email"] = val?.mainEmail;
    }

    if (
      updatedValues?.roleId !==
      (currentRole?.length > 0 ? currentRole[0]?.id : val?.permissionLevel)
    ) {
      data["roleId"] =
        currentRole?.length > 0 ? currentRole[0]?.id : val?.permissionLevel;
    }

    if (Object.keys(data).length > 0) {
      dispatch(
        RequestAppAction.UpdateUserDetails({
          data: data,
          cbSuccess: () => {
            dispatch(RequestAppAction.fetchUserDetails());
            setExpand("");
          },
        })
      );
    } else {
      setExpand("");
    }
  };

  useLayoutEffect(() => {
    dispatch(
      RequestAppAction.fetchUserSettings({
        cbSuccess: () => {
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        },
      })
    );
    dispatch(initiateFetchRoles());

    return () => {
      setUpdating(true);
    };
  }, []);

  const rolesName = () => {
    const currentRole = roles?.filter(
      (role: any) => role?.id == userSetitngDetails?.roleId
    );

    if (currentRole) {
      return currentRole[0]?.name;
    } else {
      return "";
    }
  };

  const userColoumn = (columnName: string, columnValue: string) => {
    return (
      <Col style={{ flexDirection: "column", display: "flex" }} span={6}>
        <Text strong>{columnName}</Text>
        <Text>{columnValue}</Text>
      </Col>
    );
  };

  useEffect(() => {
    if (userSetitngDetails) {
      setUpdatedValues(userSetitngDetails);
    } else {
      setUpdatedValues({});
    }
  }, [userSetitngDetails]);

  return (
    <Space
      direction="vertical"
      size={"large"}
      style={{ overflowY: "auto" }}
      className={styles.settings_wrapper}
    >
      <Spin spinning={isLoading}>
        <Space
          style={{ opacity: isLoading ? 0.1 : 1 }}
          direction="vertical"
          className={styles.settings_content_headings_wrapper}
        >
          <Space
            direction="vertical"
            size={"large"}
            style={{
              borderRadius: "0.625rem",
              width: "100%",
              border: `0.06rem solid ${Colors.Secondary}`,
              margin: "0.5rem 0",
            }}
          >
            <Space
              align="center"
              size={"large"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: Colors.SecondaryLight,
                borderRadius: "0.625rem 0.625rem 0 0",
                padding: "1.3rem 1.5rem",
                width: "100%",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Personal Settings
              </Title>
              <Space size="middle" align="center">
                <Button
                  shape="circle"
                  title=""
                  icon={<EditOutlined />}
                  onBtnClick={() => {
                    setChangePassword(false);
                    if (expand === "personal") setExpand("");
                    else setExpand("personal");
                  }}
                />
              </Space>
            </Space>
            {expand === "personal" ? (
              <Space
                size={"large"}
                direction="vertical"
                className={styles.settings_items_heading}
              >
                <div className={styles.form_wrapper}>
                  <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={updateUserDetails}
                  >
                    <Row gutter={20}>
                      <Col span={10}>
                        <Form.Item
                          name="username"
                          label="Username"
                          initialValue={userSetitngDetails?.username}
                          rules={[
                            {
                              required: true,
                              message: "Please enter username",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Username" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="contactName"
                          label="Contact Name"
                          initialValue={userSetitngDetails?.name}
                          rules={[
                            {
                              required: true,
                              message: "Please enter contact name",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Contact Name" />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name="permissionLevel"
                          label="Permission Level"
                          initialValue={rolesName()}
                          rules={[
                            {
                              required: true,
                              message: "Please select permission level",
                            },
                          ]}
                        >
                          <Select placeholder="Select Permission Level">
                            {roles.map((role: any, index: number) => (
                              <Option key={index} value={role.id}>
                                {role.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="mainEmail"
                          label="Main Email"
                          initialValue={userSetitngDetails?.email}
                          rules={[
                            {
                              required: true,
                              message: "Please enter main email",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email address",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Main Email" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="additionalEmail"
                          label="Additional Email"
                          initialValue={userSetitngDetails?.additionalEmail}
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email address",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Additional Email" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item>
                      <Space align="end" className={styles.form_button_wrapper}>
                        <Space size="middle" align="center">
                          <Button
                            onBtnClick={() => setExpand("")}
                            title="Cancel"
                            btnClass="white_btn"
                          ></Button>
                          <Button title="Save" buttonType="submit"></Button>
                        </Space>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              </Space>
            ) : (
              <Space
                size={"large"}
                direction="vertical"
                className={styles.settings_items_heading}
              >
                <Row gutter={[20, 20]} style={{ marginBottom: "1rem" }}>
                  {userColoumn("User Name", userSetitngDetails?.username)}
                  {userColoumn("Contact Name", userSetitngDetails?.name)}
                  {userColoumn("Main Email", userSetitngDetails?.email)}
                  {userColoumn(
                    "Additional Email",
                    userSetitngDetails?.additionalEmail
                  )}
                  {userColoumn("Permission Level", rolesName())}
                </Row>
              </Space>
            )}
          </Space>

          <Space
            direction="vertical"
            size={"large"}
            style={{
              borderRadius: "0.625rem",
              width: "100%",
              border: `0.06rem solid ${Colors.Secondary}`,
              margin: "0.5rem 0",
            }}
          >
            <Space
              align="center"
              size={"large"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: Colors.SecondaryLight,
                borderRadius: "0.625rem 0.625rem 0 0",
                padding: "1.3rem 1.5rem",
                width: "100%",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Notifications
              </Title>
            </Space>

            <Space
              size={"large"}
              direction="vertical"
              className={styles.settings_items_heading}
            >
              <Row gutter={[16, 0]} style={{ marginBottom: "1rem" }}>
                <Col span={4}>
                  <Switch
                    loading={isLoading || settingsLoading}
                    checked={currentAudioState}
                    label="Audio Notifications:"
                    checkedText="ON"
                    unCheckedText="OFF"
                    onChange={getAudioState}
                  />
                </Col>
                <Col span={4}>
                  <Switch
                    loading={isLoading || settingsLoading}
                    checked={currentEmailState}
                    label="Email Notifications:"
                    checkedText="ON"
                    unCheckedText="OFF"
                    onChange={getEmailState}
                  />
                </Col>
              </Row>
            </Space>
          </Space>
          <ChangePassword
            changePassword={changePassword}
            setChangePassword={setChangePassword}
            setExpand={setExpand}
          />
        </Space>
      </Spin>
    </Space>
  );
};

export default Settings;
