import { PlusCircleOutlined } from "@ant-design/icons";
import { Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRolesData } from "src/store/selectors/features/roles";
import RequestAppAction from "src/store/slices/appActions";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import Button from "../button";
import UserDetails from "../user-details";
import styles from "./addNewUser.module.scss";

const CreateNewUser = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [formData, setFormData] = useState<any>(null);
  const roles: any = useSelector(getRolesData);
  const [numberOfUserForms, setNumberOfUserForms] = useState<number>(1);

  useEffect(() => {
    dispatch(initiateFetchRoles());
  }, []);

  const userData = location.state;

  const removePlus = (str: string) => {
    if (str.startsWith("+1") && str.length === 11) {
      return str.slice(2);
    }

    if (str.startsWith("+") && str.length === 11) {
      return str.slice(1);
    }

    return str;
  };

  const getUserData = async (values: any) => {
    const numUsers = Object.keys(values).filter((key) =>
      key.startsWith("userName_")
    ).length;

    const payloadData: any = { users: [] };

    for (let i = 0; i < numUsers; i++) {
      const roleId =
        values[`permissionLevel_${i}`]?.length > 1
          ? roles?.filter(
              (item: any) => item.name === values[`permissionLevel_${i}`]
            )
          : [];

      payloadData.users.push({
        username: values[`userName_${i}`],
        name: values[`contactName_${i}`],
        roleId:
          roleId.length > 0 ? roleId[0]?.id : values[`permissionLevel_${i}`],
        status: values[`userStatus_${i}`]?.toLocaleUpperCase(),
        email: values[`mainEmail_${i}`],
        additionalEmail: values[`additionalEmail_${i}`],
        dids: [],
      });

      const didKeys = Object.keys(values).filter((key) =>
        new RegExp(`did_user${i}_\\d+$`).test(key)
      );

      const numDids = didKeys.length;

      for (let j = 0; j < numDids; j++) {
        payloadData.users[i].dids.push({
          did: removePlus(values[`did_user${i}_${j}`]),
          status: values[`didStatus_user${i}_${j}`]?.toLocaleUpperCase(),
          email: values[`notificationEmail_user${i}_${j}`],
        });
      }
    }

    dispatch(
      RequestAppAction.addNewUser({
        id: userData.id,
        data: payloadData,
        cbSuccess: () => {
          navigate(-1);
        },
      })
    );
  };

  const userFormsArray = Array.from(
    { length: numberOfUserForms },
    (_, index) => index
  );

  const handleUserDetailsForm = (userdata: any, userIndex: any) => {
    const updatedFormData = { ...formData };
    updatedFormData[`userDetails${userIndex}`] = userdata;
    setFormData(updatedFormData);
  };

  const userForms = userFormsArray.map((item, i) => (
    <UserDetails
      key={i}
      setNumberOfUserForms={setNumberOfUserForms}
      items={item}
      count={i}
      form={form}
      isFieldRequired={true}
      handleFieldChange={() => {}}
      onUserDetailsForm={(userdata: any) => handleUserDetailsForm(userdata, i)}
    />
  ));

  return (
    <Space
      className={styles.new_user_wrapper}
      direction="vertical"
      size={"large"}
    >
      <Form onFinish={getUserData} layout={"vertical"} form={form}>
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Button
            title="Add New"
            icon={<PlusCircleOutlined />}
            onBtnClick={() => {
              if (numberOfUserForms < 5) {
                setNumberOfUserForms(numberOfUserForms + 1);
              }
            }}
          />
          <Button
            title="Cancel"
            onBtnClick={() => navigate(-1)}
            btnClass="outlined_btn"
          />

          <Button title="Save" buttonType="submit" />
        </Space>
        <div
          style={{
            paddingTop: "0.5rem",
            maxHeight: "46rem",
            overflowY: "auto",
            padding: "1rem 0.5rem 14rem 0.5rem",
          }}
        >
          {userForms}
        </div>
      </Form>
    </Space>
  );
};

export default CreateNewUser;
