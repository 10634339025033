import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const reportsSelector = (state: any) => state.features.reports;

export const getBaseUrl = createSelector(
  reportsSelector,
  (app) => app.baseUrl
);

export const getreportsData = createSelector(
  reportsSelector,
  (app) => app.reportData
);

export const getreportState = createSelector(
  reportsSelector,
  (app) => app.reportState
);

export const getreportsLoadingState = createSelector(
  getreportState,
  (reportState) => reportState.isLoading
);


