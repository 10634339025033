import React, { memo } from "react";
import { useSelector } from "react-redux";
import PublicRoutes from "./public-routes";

const Auth = () => {
  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  return <PublicRoutes loggedIn={loggedIn} />;
};

export default memo(Auth);
