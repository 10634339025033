import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Accounts } from "src/pages/accounts";
import { AddNewUser } from "src/pages/accounts/add-new-user";
import { CreateNewAccount } from "src/pages/accounts/create-account";
import { TextifyUsers } from "src/pages/accounts/textify-users";
import { Reports } from "src/pages/reports";
import ReportsById from "src/pages/reports/reports-by-id";
import Settings from "src/pages/settings";

const DashboardContent = () => (
  <Routes>
    <Route path="/*" element={<Navigate to={"/accounts"} />}></Route>
    <Route path="/accounts" element={<Accounts />}></Route>
    <Route path="/accounts/create-new-account" element={<CreateNewAccount />} />
    <Route path="/accounts/:name" element={<TextifyUsers />} />
    <Route path="/accounts/:name/add-new-user" element={<AddNewUser />} />
    <Route path="/reports" element={<Reports />}></Route>
    <Route path="/reports/:id" element={<ReportsById />}></Route>
    <Route path="/settings" element={<Settings />}></Route>
  </Routes>
);

export default DashboardContent;
