import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { addDidFailure, addDidSuccess } from "../slices/features/did";
import { RequestTypes } from "../types";

const didsService = new AppService();

function* addDid(action: any) {
  const { payload } = action;
  const { id, data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      didsService.addDid,
      baseUrl,
      id,
      data
    );

    payload?.cbSuccess && payload?.cbSuccess();

    yield put(addDidSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(addDidFailure({ statusCode, statusText }));
  }
}

export function* wathcAddDid() {
  yield takeLatest(RequestTypes.ADD_DID_REQUEST, addDid);
}
